import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { MdDeleteForever } from 'react-icons/md';
import CustomModal from '../../component/UI Component/Modal';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import CommonTable from '../../component/Common table/Table';
import { Tooltip } from 'antd';

const List_Membership = () => {
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_API_MEMBERSHIPS.toString();
    const deleteapiname = process.env.REACT_APP_API_MEMBERSHIPS.toString();

    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const itemsPerPage = 10;
    const [response, setResponse] = useState()
    const [state, setState] = useState({
        open: false
    });
    const query = new URLSearchParams({
        search: inputValue !== '' ? inputValue : '',
        page: currentPage,
        limit: 10,
    }).toString();

    const { data, error, isFetching, refetch } = useFetch(`${apiname}?${query}`);

    useEffect(() => {
        if (isFetching && error === null) {
            refetch();
        }
    }, [inputValue, currentPage]);

    const headers = [
        { key: 'id', value: 'Sr No.' },
        { key: 'membership_number', value: 'Membership Number' },
        { key: 'membership_name', value: 'Membership Name' },
        { key: 'monthly_dues', value: 'Monthly Dues' },
        { key: 'required_draws', value: 'Required Draws' },
        { key: 'monthly_freeze_dues', value: 'Monthly Freeze Dues' },
        { key: 'required_draws_before_freeze', value: 'Required Draws Before Freeze' },
        { key: 'tan_tax_exempt', value: 'Tan Tax Exempt' },
        { key: 'current_sunlync_document_name', value: 'Current Sunlync Document Name' },
        { key: 'membership_details', value: 'Membership Details' },
        { key: 'action', value: 'Action' }
    ];
    const startIndex = (currentPage - 1) * 10;

    const rows = data?.data?.map((e, index) => ({
        id: e.id,
        index: startIndex + index + 1,
        membership_number: e.membership_number,
        membership_name: e.membership_name,
        monthly_dues: e.monthly_dues,
        required_draws: e.required_draws,
        monthly_freeze_dues: e.monthly_freeze_dues,
        required_draws_before_freeze: e.required_draws_before_freeze === 1 ? 'True' : 'False',
        tan_tax_exempt: e.tan_tax_exempt === 1 ? 'True' : 'False',
        current_sunlync_document_name: e.current_sunlync_document_name,
        membership_details: <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{e.membership_details}</p>} placement="top" arrow >
            <div>
                {e.membership_details?.length >= 20 ? e.membership_details?.slice(0, 19)?.concat('...') : e.membership_details}
            </div>
        </Tooltip>,
    }));

    const handleSearchChange = (query) => {
        setInputValue(query);
        setCurrentPage(1);
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAddClick = () => {
        navigate('/layout/AddMembership');
    };

    const totalPages = Math.ceil(data?.totalRows / itemsPerPage);

    return (
        <>
        {(data === "There is no Membership yet!" || error) ? (
                <p className='flex justify-center text-3xl'>{error ? error.message : "No Membership"}</p>
            ):(
            <div>
                <CommonTable
                    showAddButton={true}
                    showSearch={true}
                    ButtonLabel={"Add Membership"}
                    headers={headers}
                    rows={rows || []}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    onEditClick={(id) => navigate(`/layout/EditMembership/${id}`)}
                    apiname={apiname}
                    deleteapiname={deleteapiname}
                    refetch={refetch}
                    onAddClick={handleAddClick}
                    searchQuery={inputValue}
                    onSearchChange={handleSearchChange}
                />
            </div>
            )}
        </>
    );
};

export default List_Membership;