import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useModal } from '../component/UI Component/OpenModal';

// const localStorageData = localStorage.getItem('items')
// const tokenData = localStorageData ? JSON.parse(localStorageData) : null
export const useCreate = (apiname, user) => {
    const [error, setError] = useState(null);
    const localStorageData = localStorage.getItem('items');
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null;

    const localUserData = localStorage.getItem('user');
    const userData = localUserData ? JSON.parse(localUserData) : null;
    const navigate = useNavigate()
    return useMutation(
        async (data) => {
            // debugger
            try {
                const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiname}`, data,
                    {
                        headers: {
                            'authorization': `Bearer ${userData ? userData?.token : tokenData?.token}`
                        }
                    })
                return postapicall.data
            } catch (error) {
                if (error?.response?.data?.err?.message === "jwt expired") {
                    localStorage.removeItem('items')
                    navigate('/')
                }
                // if (error?.response?.data?.message) {
                //     setError(error?.response?.data?.message)
                // }
                // if (error?.response?.data?.error) {
                //     setError(error?.response?.data?.error)
                // }
                const errorMessage = error?.response?.data?.message || error?.response?.data?.error || 'An error occurred';
                setError(errorMessage);

                // Throw the error to indicate the mutation failed
                throw new Error(errorMessage);
            }
        },
        {
            // onError: (err) => {
            //     // This will capture and handle any error thrown in the async function
            //     // setError(err.message || 'Server Error');
            //     // console.log(err.message)
            //     // return err.message
            // },
            onSuccess: (data) => {
                return data;
                // Optionally handle success side effects here
            }
        }
    )
}

// export const useFetch = async (apiname) => {
//     const navigate = useNavigate()
//     const data=async()=>{
//     try {
//      const response =     await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname}/`,
//             {
//                 headers: {
//                     'authorization': `Bearer ${tokenData?.token}`
//                 }
//             })

//         return response?.data?.results

//     } catch (error) {
//         if (error.response.data.err.message === "jwt expired") {
//             localStorage.removeItem('items')
//             navigate('/')
//         }
//     }
// }

// return {data}

// }
export const useFetch = (apiname,page,limit, store_uuid, form_category, user_id) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const navigate = useNavigate();
    const localStorageData = localStorage.getItem('items');
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null;

    const localUserData = localStorage.getItem('user');
    const userData = localUserData ? JSON.parse(localUserData) : null;
    const { openModal } = useModal();

    const fetchData = useCallback(async () => {
        setIsFetching(true);
        try {
            // debugger
            let url = ''

            if(page && limit && !store_uuid){

               url = `${process.env.REACT_APP_BASE_URL}${apiname}?page=${page}&limit=${limit}`
            }else if(store_uuid && form_category && user_id){
                url = `${process.env.REACT_APP_BASE_URL}${apiname}/category/${form_category}?store_uuid=${store_uuid}&form_category=${form_category}&user_id=${user_id}`
            }
            else{
               url = `${process.env.REACT_APP_BASE_URL}${apiname}`
            }
            const response = await axios.get(url, {
                headers: {
                    'authorization': `Bearer ${userData ? userData?.token : tokenData?.token}`,
                    // "ngrok-skip-browser-warning":'1'
                },
            });
            setData(response.data.results);
            setError(null);
        } catch (error) {
            // console.log(error?.response?.data?.err?.message, "error");   
            if (
                error?.response?.data?.err?.message === "jwt expired" ||
                error?.response?.data?.err?.message === "Failed to authenticate token." ||
                error?.response?.data?.err?.message === "jwt malformed error"
            ) {
                setIsFetching(false);
                openModal({
                    title: 'Session Expired',
                    text: 'Token has expired. Please login again.',
                    onYes: () => {
                        setTimeout(() => {
                            localStorage.removeItem('items');
                            localStorage.removeItem('tokenData');
                            navigate('/');
                        }, 0);
                    },
                });
                setData(null);
            } else {
                setError(error?.response?.data?.message);
            }
        } finally {
            setIsFetching(false);
        }

    }, [apiname, tokenData?.token, navigate, openModal]);

    useEffect(() => {
            fetchData()
    }, [apiname]);

    return { data, error, isFetching, refetch: fetchData }
};
export const Update = async (input, status = false) => {
    try {
        const localStorageData = localStorage.getItem('items')
        const tokenData = localStorageData ? JSON.parse(localStorageData) : null
        const url = await axios.put(`${process.env.REACT_APP_BASE_URL}${input.apiname}/${input.id}`, input.data, {
            headers: {
                'authorization': `Bearer ${tokenData?.token}`
            },
        });
        // const apiUrl = status ? `${url}?edit=yes` : url;
        // const response = await axios.put(url, input.data);
        return url.data;
    } catch (error) {
        // Handle or throw the error
        console.error('API call error:', error);
        return error;
    }
};

export const useUpdateSingle = (status) => {
    return useMutation((input) => Update(input, status))
}

const FetchSingleApi = async (id, apiname, trigger) => {
    const localStorageData = localStorage.getItem('items')
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null

    return await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, {
        headers: {
            'authorization': `Bearer ${tokenData?.token}`
        }
    })
}

export const useFetchSingle = (id, apiname, trigger) => {
    return useQuery(['Single', id, apiname, trigger], async () => await FetchSingleApi(id, apiname, trigger), {
        select: (data) => {
            const results = data.data?.results
            return results
        }
    })
}