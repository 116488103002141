import React, { useState } from 'react';
import { Button, Modal, Space } from 'antd';

const CustomModal = ({ open, onClose, onYes, onNo, text, title, Ok }) => {

    return (
        <>
            <Modal
                open={open}
                title={title}
                footer={null}
                onCancel={onNo}
            >
                <div className="flex justify-center flex-col">
                    {text ? (
                        <p className='flex justify-center'>{text}</p>
                    ) : null}
                    <Space className='flex justify-center'>
                        {
                            onYes ? (
                                <Button onClick={onYes} type="primary">
                                    Yes
                                </Button>
                            ) : null
                        }
                        {
                            onNo ? (
                                <Button onClick={onNo}>No</Button>
                            ) : null
                        }
                        {
                            Ok ? (
                                <Button onClick={Ok}>Ok</Button>
                            ) : null
                        }
                    </Space>

                </div>
            </Modal>
        </>
    );
};

export default CustomModal;