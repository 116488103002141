import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreate } from '../../hooks/Api'
import Snackbar from '@mui/material/Snackbar';
import { message } from 'antd';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
import { useFetch } from '../../hooks/Api';
import axios from 'axios';
import { useMutation } from 'react-query';

const Edit_Store = () => {
  const { id } = useParams();

  const apiname = process.env.REACT_APP_API_STORES?.toString();
  const apinameAddress = process.env.REACT_APP_API_ADDRESSES?.toString();
  const apinamePhone = process.env.REACT_APP_API_PHONES?.toString();
  const apinameState = process.env.REACT_APP_API_STATES?.toString();
  const apinameCity = process.env.REACT_APP_API_CITIES?.toString();
  const apinameZip = process.env.REACT_APP_API_ZIPCODES?.toString();
  const { data: stateData, error: stateError, isFetching: stateIsFetching, refetch: stateRefetch } = useFetch(apinameState);


  const [stateOptions, setStateOptions] = useState([])
  useEffect(() => {
    if (stateData) {
      let data = stateData?.data?.map((e) => { return { label: e.state_name, value: e.id } })
      setStateOptions(data)
    }
  }, [stateData])

  const onChange = (value) => {
    setValue('state_id', value)
    setStateValue(value)
    clearErrors('state_id')
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  console.log(stateOptions)


  const { data: addressData, error: addressError, isFetching: addressIsFetching, refetch: addressRefetch } = useFetch(apinameAddress);


  const [addressOptions, setaddressOptions] = useState([])
  useEffect(() => {
    if (addressData) {
      let data = addressData?.data?.map((e) => { return { label: e.address, value: e.id } })
      setaddressOptions(data)
    }
  }, [addressData])

  const onChangeAddress = (value) => {
    setValue('address_id', value)
    setAddressValue(value)
    clearErrors('address_id')
  };

  const onSearchAddress = (value) => {
    console.log('search:', value);
  };



  const { data: phoneData, error: phoneError, isFetching: phoneIsFetching, refetch: phoneRefetch } = useFetch(apinamePhone);


  const [phoneOptions, setphoneOptions] = useState([])
  useEffect(() => {
    if (phoneData) {
      let data = phoneData?.data?.map((e) => { return { label: e.phone, value: e.id } })
      setphoneOptions(data)
    }
  }, [phoneData])

  const onChangephone = (value) => {
    setValue('phone_id', value)
    setPhoneValue(value)
    clearErrors('phone_id')

  };

  const onSearchphone = (value) => {
    console.log('search:', value);
  };




  const { data: cityData, error: cityError, isFetching: cityIsFetching, refetch: cityRefetch } = useFetch(apinameCity);


  const [cityOptions, setcityOptions] = useState([])
  useEffect(() => {
    if (cityData) {
      let data = cityData?.data?.map((e) => { return { label: e.city_name, value: e.id } })
      setcityOptions(data)
    }
  }, [cityData])

  const onChangecity = (value) => {
    setValue('city_id', value)
    setCityValue(value)

    clearErrors('city_id')
  };

  const onSearchcity = (value) => {
    console.log('search:', value);
  };



  const { data: zipData, error: zipError, isFetching: zipIsFetching, refetch: zipRefetch } = useFetch(apinameZip);


  const [zipOptions, setzipOptions] = useState([])
  useEffect(() => {
    if (zipData) {
      let data = zipData?.data?.map((e) => { return { label: e.zip_code, value: e.id } })
      setzipOptions(data)
    }
  }, [zipData])

  const onChangezip = (value) => {
    setValue('zip_id', value)
    setZipValue(value)

    clearErrors('zip_id')
  };

  const onSearchzip = (value) => {
    console.log('search:', value);
  };

  const { mutate, data, isError, isSuccess, error, isLoading } = useCreate(apiname);
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset } = useForm();
  const localStorageData = localStorage.getItem('items')
  const tokenData = localStorageData ? JSON.parse(localStorageData) : null
  const navigate = useNavigate()

  // const onSubmit = (data) => {
  //   let finalData = {
  //     ...data,
  //   }
  //   mutate(finalData)
  // }



  // const { data:editData, error:editError, isFetching:editIsFetching, refetch:editRefetch } = useFetch(apinameState);

  // console.log('editData',editData)

  const [editData, setEditData] = useState()
  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      }
    });
    console.log(res)
    setEditData(res.data);
  }
  const [stateValue, setStateValue] = useState([])
  const [addressValue, setAddressValue] = useState([])
  const [phoneValue, setPhoneValue] = useState([])
  const [cityValue, setCityValue] = useState([])
  const [zipValue, setZipValue] = useState([])

  // console.log(editData)
  useEffect(() => {
    if (editData) {
      reset(editData?.results)
      const matchingState = stateOptions?.find((e) => e.value === editData?.results?.state_id);
      setValue('state_id', matchingState?.value)
      setStateValue(matchingState)

      const matchingAddress = addressOptions?.find((e) => e.value === editData?.results?.address_id);
      setValue('address_id', matchingAddress?.value)
      setAddressValue(matchingAddress)

      const matchingPhone = phoneOptions?.find((e) => e.value === editData?.results?.phone_id);
      setValue('phone_id', matchingPhone?.value)
      setPhoneValue(matchingPhone)

      const matchingCity = cityOptions?.find((e) => e.value === editData?.results?.city_id);
      setValue('city_id', matchingCity?.value)
      setCityValue(matchingCity)

      const matchingZip = zipOptions?.find((e) => e.value === editData?.results?.zip_id);
      setValue('zip_id', matchingZip?.value)
      setZipValue(matchingZip)
    }
  }, [editData, stateOptions, addressOptions, cityOptions, phoneOptions, zipOptions, setValue])









  const [state, setState] = useState({
    open: false
  });

  // useEffect(() => {
  //   if (isSuccess) {
  //     setState({ open: true });
  //     setTimeout(() => {
  //       navigate("/layout/liststore");
  //     }, 1000);
  //   } else if (isError) {
  //     console.log(error, "isError");
  //     setState({ open: true });
  //   }
  // }, [isSuccess, isError, error, data, navigate]);

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (updateData?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };




  const { error: updateError, mutate: updateMutate, isError: updateIsError, isLoading: updateIsLoading, data: updateData, isSuccess: updateIsSuccess } = useMutation(async (data) => {
    const updateStoreUserDetails = await axios.put(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, data, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      },
    })
    return updateStoreUserDetails.data
  })

  const onSubmit = (data) => {
    let finalData = {
      ...data,
    }
    updateMutate(finalData)
  }
  useEffect(() => {
    if (updateIsSuccess || updateData) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/stores");
      }, 1000);
    } else if (updateIsError) {
      setState({ open: true });
    }
  }, [updateData, updateError, updateIsSuccess, navigate]);

  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Edit Store</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="">
                  Store Name
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  {/* <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg> */}
                  <input
                    {...register('store_name', { required: { value: true, message: 'Store Name Is Required' } })}
                    type="text"
                    id=""
                    className="flex-1 border border-none focus:border focus:border-none"
                    placeholder="Store Name"
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.store_name?.message}</p>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="">
                  Address
                </label>
                <Select
                  {...register('address_id', { required: { value: true, message: 'Address Is Required' } })}

                  className='w-full h-8.5'
                  showSearch
                  value={addressValue}
                  placeholder="Select an Address"
                  optionFilterProp="label"
                  onChange={onChangeAddress}
                  onSearch={onSearchAddress}
                  options={addressOptions}
                />
                <p style={{ color: "Red" }}>{errors?.address_id?.message}</p>
              </div>



              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="">
                  Phone Number
                </label>
                <Select
                  {...register('phone_id', { required: { value: true, message: 'Phone Number Is Required' } })}

                  className='w-full h-8.5'
                  showSearch
                  value={phoneValue}
                  placeholder="Select a Phone Number"
                  optionFilterProp="label"
                  onChange={onChangephone}
                  onSearch={onSearchphone}
                  options={phoneOptions}
                />
                <p style={{ color: "Red" }}>{errors?.phone_id?.message}</p>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="storeName">
                  State Name
                </label>
                <Select
                  {...register('state_id', { required: { value: true, message: 'State Is Required' } })}

                  className='w-full h-8.5'
                  showSearch
                  value={stateValue}
                  placeholder="Select a State"
                  optionFilterProp="label"
                  onChange={onChange}
                  onSearch={onSearch}
                  options={stateOptions}
                />
                <p style={{ color: "Red" }}>{errors?.state_id?.message}</p>
              </div>


              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="">
                  City Name
                </label>
                <Select
                  {...register('city_id', { required: { value: true, message: 'City Is Required' } })}

                  className='w-full h-8.5'
                  showSearch
                  value={cityValue}
                  placeholder="Select a city"
                  optionFilterProp="label"
                  onChange={onChangecity}
                  onSearch={onSearchcity}
                  options={cityOptions}
                />
                <p style={{ color: "Red" }}>{errors?.city_id?.message}</p>
              </div>


              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="">
                  Zip Code
                </label>
                <Select
                  {...register('zip_id', { required: { value: true, message: 'Zipcode Is Required' } })}

                  className='w-full h-8.5'
                  showSearch
                  value={zipValue}
                  placeholder="Select a zipcode"
                  optionFilterProp="label"
                  onChange={onChangezip}
                  onSearch={onSearchzip}
                  options={zipOptions}
                />
                <p style={{ color: "Red" }}>{errors?.zip_id?.message}</p>
              </div>



            </div>
            <div className='text-center'>
              <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
      {/* <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'right' }}
      open={state.open}
      onClose={handleClose}
      TransitionComponent={state.Transition}
      message={error?.response?.data?.message}
      key={state?.Transition?.name}
      autoHideDuration={3000}
      /> */}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        onExited={() => {
          if (updateIsSuccess) {
            navigate("/layout/stores");
          }
        }}
        message={updateError?.response?.data?.message || updateData?.message || 'An error occurred'}
      />
      {
        isLoading ? (
          <Loader />
        ) : null
      }

    </>
  );
}

export default Edit_Store;
