import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreate } from '../../hooks/Api'
import Snackbar from '@mui/material/Snackbar';
import { message } from 'antd';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
import { useFetch } from '../../hooks/Api';

const Add_State = () => {

  const apiname = process.env.REACT_APP_API_STATES?.toString();

  const { mutate, data, isError, isSuccess, error, isLoading } = useCreate(apiname);
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
  const navigate = useNavigate()

  const onSubmit = (data) => {
    let finalData = {
      ...data,
    }
    mutate(finalData)
  }


 



  const [state, setState] = useState({
    open: false
  });



  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      console.log(data?.message,"state updated")
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };




  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/states");
      }, 1000);
    } else if (isError) {
      console.log(error, "isError");
      setState({ open: true });
    }
  }, [isSuccess, isError, error, data, navigate]);
  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Add State</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="StoreManagerName">
                  State Name
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  {/* <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg> */}
                  <input
                    {...register('state_name', { required: { value: true, message: 'State Name Is Required' },
                      pattern: {
                        value: /^(?!^\s)(?!.*\s{2,})(?!.*--)(?=.*[a-zA-Z])[a-zA-Z0-9_.\-]+(?: [a-zA-Z0-9_.\-]+)*$/    ,
                         message: 'State name can contain only Alphabets, numbers, hyphen, underscore and dot',
                       }, })}
                    type="text"
                    id="StoreManagerName"
                    className="flex-1 border border-none focus:border focus:border-none"
                    placeholder="State Name"
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.state_name?.message}</p>
              </div>


          
            </div>
            <div className='text-center'>
              <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
     

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        onExited={() => {
          if (isSuccess) {
            navigate("/layout/states");
          }
        }}
        message={error?.response?.data?.message || data?.message || error?.message}
      />
      {
        isLoading ? (
          <Loader/>
        ):null
      }

    </>
  );
}

export default Add_State;
