import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Latan from "../../images/copy1.png";
import Latannewlogo from "../../images/sore_1.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import Signature from "../../component/signpad";
import { useCreate } from "../../hooks/Api";
// import { Snackbar } from '@mui/material';
import Loader from "../../component/UI Component/Loader";
import { Snackbar, Typography } from "@mui/material";
import SignaturePad from "../../component/custom_signpad";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";

const apiname = process.env.REACT_APP_API_FORM_ANS?.toString();
const apiname1 = process.env.REACT_APP_API_FORM_BY_NAME.toString();
const getApi = process.env.REACT_APP_API_USER_FORM.toString();

const FormDetailsByName = () => {
  const { user_id,form_name } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const navigate = useNavigate();
  //  const completed = JSON.parse(localStorage.getItem('completed'));
  // const completedForms = completed ? completed : false;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm();
  const { mutate, data, isError, isSuccess, error, isLoading } =
    useCreate(apiname);
  const [details, setDetails] = useState([]);
  const [formDetails, setFormDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [signatureData, setSignatureData] = useState({});
  const [requiredSignatures, setRequiredSignatures] = useState(new Set());
  const [localData, setLocalData] = useState({});
  const [currentStep, setCurrentStep] = useState(0); // Step state
  const [state, setState] = React.useState({
    open: false,
  });

  useEffect(() => {
    if (error || isError) {
      setLoading(false);
    }
  }, [error, isError]);

  useEffect(() => {
    const completed = JSON.parse(localStorage.getItem("user"));

    setLocalData(completed);
    fetchData();
  }, [form_name, setLocalData]);
  // useEffect(() => {
  //     fetchData();
  // }, [id]);

  useEffect(() => {
    if (details.length) {
      const required = new Set(
        details
          ?.filter((field) => field?.type === "sign pad" && field?.required)
          .map((field) => field?.Key)
      );
      setRequiredSignatures(required);
    }
  }, [details]);

  const [getDetails, setGetDetails] = useState([]);

  useEffect(() => {
    const getDatatype = typeof (formDetails?.form_fields)
    const getparseData = getDatatype === "string" ? JSON.parse(formDetails?.form_fields) : formDetails?.form_fields
    if (getparseData) {
        getparseData.forEach(field => {
            if (field.answer !== undefined) {
                setValue(field.Key, field.answer);
            }
            if (field.radioTextanswer !== undefined) {
                setValue(`${field.Key}_radioText`, field.radioTextanswer);
            }
            if (field.type === 'sign pad' && field.answer) {
                setSignatureData(prevState => ({
                    ...prevState,
                    [field.Key]: field.answer
                }));
            }
        });
    }
}, [formDetails, setValue]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const localStorageData = localStorage.getItem("items");
      const tokenData = localStorageData ? JSON.parse(localStorageData) : null;
      // if (completedForms) {
      //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${getApi}/${tokenData.id}/${id}`, {
      //         headers: {
      //           'Content-Type': 'application/pdf',
      //           'authorization': `Bearer ${tokenData?.token}`,
      //           "ngrok-skip-browser-warning":'1'
      //         },
      //         responseType: 'blob' // Important: This tells axios to treat the response as binary data
      //       })

      //       const blobUrl = URL.createObjectURL(response.data);
      //       setPdfUrl(blobUrl);

      // } else {

      try {
        setLoading(true);
        const localStorageData = localStorage.getItem("user");
        const tokenData = localStorageData
          ? JSON.parse(localStorageData)
          : null;

        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${apiname1}/${user_id}/${localData?.store_uuid}/${form_name}`,
          {
            headers: {
              authorization: `Bearer ${tokenData?.token}`,
              "ngrok-skip-browser-warning": "1",
            },
          }
        );
        setFormDetails(res.data.results);
        const getDetails = JSON.parse(res?.data?.results?.form_fields);
        localStorage.setItem(
          "membership_id",
          res?.data?.results?.membership_id
        );
        const modifiedData = modifyData(getDetails);
        setDetails(modifiedData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
      // }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // const fetchData = async () => {

  // };

  const calculateTotal = (array) => {
    return array.reduce((total, item) => {
      if (item.type === "dropdown") {
        const answerNumber = Number(item.answer);
        if (!isNaN(answerNumber)) {
          return total + answerNumber;
        } else {
          console.error(`Invalid number for answer: ${item.answer}`);
        }
      }
      return total;
    }, 0);
  };

  // const modifyData = (data) => {

  const modifyData = (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid data format:", data);
      return [];
    }
    const getData = data.form_fields ? data.form_fields : data;
    const modifyData = getData.map((details) => {
      if (details.type === "checkbox") {
        details.checkBoxOption = details?.options?.map((optionObject) => {
          const key = Object.keys(optionObject)[0];
          return String(optionObject[key]);
        });
        delete details.options;
      }

      if (details.type === "radio") {
        details.radioOptions = details?.radioOptions?.map((optionObject) => {
          // const key = Object.keys(optionObject)[0];
          return optionObject;
        });
        if (details?.options) {
          details.radioOptions = details?.options?.map((optionObject) => {
            const key = Object.keys(optionObject)[0];
            return String(optionObject[key]);
          });
        }
        delete details.options;
      }

      if (details.type === "dropdown") {
        details.option = details?.dropdownOption?.map((option) => ({
          value: option.value,
          label: option.label,
        }));
        delete details.options;
      }

      if (["short", "paragraph", "date", "sign pad"].includes(details.type)) {
        delete details.checkBoxOption;
        delete details.radioOptions;
        delete details.option;
        if (details.type === "date") {
          details.format = "MM/DD/YYYY";
        }
      }
      return details;
    });
    return modifyData;
  };

  const onSubmit = async (data) => {
    try {
      const disableSubmit = await isSubmitDisabled();

      if (disableSubmit) {
        // If submit should be disabled, do nothing or show an error message
        console.log("Form is invalid or signatures are missing.");
        return;
      }
      setLoading(true);
      const getParseData = JSON.parse(formDetails.form_fields);
      const finalData = getParseData.map((field) => {
        if (field.type === "sign pad") {
          return { ...field, answer: signatureData[field.Key] || "" };
        } else if (field.type === "radio") {
          const radioTextDataKeys = field?.radioTextData?.map(
            (option) => `${field.Key}_radiotext_${option.Key}`
          );
          const updatedRadioTextData = field?.radioTextData?.map((option) => {
            const dataKey = `${field.Key}_radiotext_${option.Key}`;
            return {
              ...option,
              answer: data[dataKey] || "",
            };
          });
          delete field.dropdownOption;
          return {
            ...field,
            answer: data[field.Key] || "",
            radioTextData: updatedRadioTextData,
          };
        } else {
          const formValue = data[field.Key];
          return {
            ...field,
            answer: formValue !== undefined ? formValue : "",
          };
        }
      });

      const totalCount = calculateTotal(finalData);

      const newObject = {
        answer: totalCount,
        Key: "total",
      };

      finalData.push(newObject);

      const finalObject = {
        form_name: formDetails.form_name,
        form_fields: finalData,
        form_description: formDetails.form_description,
        file_name: formDetails.file_name,
      };

      mutate({
        form_id: formDetails?.id,
        user_id: localData?.id,
        store_id: localData?.store_uuid,
        answers: finalObject,
        file_name: formDetails?.file_name,
        email: localData?.email,
        mobile: localData?.mobile,
        dob: localData?.dob,
        membership_id: localStorage.getItem("membership_id"),
      });
    } catch (error) {
      console.log("🚀 ~ onSubmit ~ error:", error);
      setLoading(false);
    }
  };

  //     useEffect(()=>{
  //         const getParseData = JSON.parse(formDetails.form_fields);
  //         const finalData = getParseData.map(field => {
  //             if (field.type === 'sign pad') {
  //                 return { ...field, answer: signatureData[field.Key] || '' };
  //             }

  //     else if (field.type === 'radio') {
  //         const radioTextDataKeys = field?.radioTextData?.map(option => `${field.Key}_radiotext_${option.Key}`);
  //         const updatedRadioTextData = field?.radioTextData?.map(option => {
  //             const dataKey = `${field.Key}_radiotext_${option.Key}`;
  //             return {
  //                 ...option,
  //                 answer: data[dataKey] || ''
  //             };
  //         });
  //         delete field.dropdownOption;
  //         return {
  //             ...field,
  //             answer: data[field.Key] || '',
  //             radioTextData: updatedRadioTextData
  //         };
  //     } else {
  //         const formValue = data[field.Key];
  //         return {
  //             ...field,
  //             answer: formValue !== undefined ? formValue : ''
  //         };
  //     }
  // });

  //     },[formDetails])

  // Stepper Logic
  // const handleNext = async () => {
  //     const valid = await trigger(); // Validate current step
  //     if (valid) setCurrentStep(prevStep => prevStep + 1);
  // };

  // const handlePrevious = () => setCurrentStep(prevStep => Math.max(prevStep - 1, 0));

  const groupFieldsByButtons = (fields) => {
    const groups = [];
    let group = [];

    fields.forEach((field) => {
      if (field.type === "button") {
        if (group.length) {
          groups.push(group);
          group = [];
        }
      } else {
        group.push(field);
      }
    });

    if (group.length) {
      groups.push(group);
    }

    return groups.filter((group) => group.length > 0); // Filter out empty groups
  };

  const groupedFields = groupFieldsByButtons(details);

  // const handleNext = async () => {
  //     const valid = await trigger(); // Validate current step
  //     if (valid && !isLastStep) {
  //         setCurrentStep(prevStep => prevStep + 1);
  //     }
  // };

  const handleNext = async () => {
    const valid = await trigger(); // Validate current step fields
    const requiredSignaturesForStep = groupedFields[currentStep].filter(
      (field) => field.type === "sign pad" && field.required
    );

    // Check if required signatures are filled
    const allSignaturesFilled = requiredSignaturesForStep.every(
      (field) => signatureData[field.Key]
    );

    // Proceed only if form is valid and all required signatures are filled
    if (valid && allSignaturesFilled && !isLastStep) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  // Function to check if the "Next" button should be disabled
  const isNextButtonDisabled = () => {
    const requiredSignaturesForStep = groupedFields[currentStep].filter(
      (field) => field.type === "sign pad" && field.required
    );

    // Disable Next button if any required signatures are not filled
    const allSignaturesFilled = requiredSignaturesForStep.every(
      (field) => signatureData[field.Key]
    );

    return !allSignaturesFilled;
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // console.log(localData?.personal_details)

//   const userPersonalDetails = localData?.personal_details
//     ? JSON.parse(localData?.personal_details)
//     : null;
  // Filter out button fields and check if we are at the last non-button step
  const nonButtonFields = details.filter((field) => field.type !== "button");
  const isLastStep = currentStep === groupedFields.length - 1;

  
  // const onChange = (date, dateString) => {
  //     setValue('dob',dateString)
  //   };

  const renderFormField = (field, index) => {
    if (field.type === "button") return null; // Skip button fields
    switch (field.type) {
      case "short":
        return (
          <div key={field.Key} className="flex flex-col">
            <label className="text-lg font-medium text-black mb-1">
              {field.label}
            </label>
            <input
              // initialData={field.Key === 'first_name' ? userPersonalDetails?.first_name :''}
              // value={field.Key === 'first_name' ? userPersonalDetails?.first_name :''}
            //   defaultValue={getDetails[index]?.answer || "-"} // Controlled input from `getDetails`
              {...register(field.Key, {
                required: {
                  value: field.required,
                  message: `${field.label} is required`,
                },
              })}
            //   onChange={(e) => handleFieldChange(e, field, index)}
              className="w-full border border-gray-300 rounded-lg p-2"
            />
            {errors[field.Key] && (
              <span className="text-red-500 text-sm mt-1">
                {errors[field.Key]?.message}
              </span>
            )}
          </div>
        );
      case "h1":
        return (
          <div key={field.Key} className="flex flex-col ">
            <Typography variant="h1" component="h1">
              {field.label}
            </Typography>
          </div>
        );

      case "h2":
        return (
          <div key={field.Key} className="flex flex-col">
            <Typography variant="h2" component="h2">
              {field.label}
            </Typography>
          </div>
        );
      case "h3":
        return (
          <div key={field.Key} className="flex flex-col">
            <Typography variant="h3" component="h3">
              {field.label}
            </Typography>
          </div>
        );
      case "h4":
        return (
          <div key={field.Key} className="flex flex-col">
            <Typography variant="h4" component="h4">
              {field.label}
            </Typography>
          </div>
        );
      case "h5":
        return (
          <div key={field.Key} className="flex flex-col">
            <Typography variant="h5" component="h5">
              {field.label}
            </Typography>
          </div>
        );

      case "normal text":
        return (
          <div key={field.Key} className="flex flex-col ">
            <Typography variant="p" component="p">
              {field.label}
            </Typography>
          </div>
        );

      case "paragraph":
        return (
          <div key={field.Key} className="flex flex-col">
            <label className="text-lg font-medium text-black mb-1">
              {field.label}
            </label>
            <textarea
              {...register(field.Key, {
                required: {
                  value: field.required,
                  message: `${field.label} is required`,
                },
              })}
              className="w-full border border-gray-300 rounded-lg p-2"
            />
            {errors[field.Key] && (
              <span className="text-red-500 text-sm mt-1">
                {errors[field.Key]?.message}
              </span>
            )}
          </div>
        );
     
      case "radio":
        return (
          <div key={field.Key} className="flex flex-col">
            <label className="text-lg font-medium text-black mb-2">
              {field.label}
            </label>
            <div className="flex flex-wrap gap-1 mb-2">
              {field?.radioOptions?.map((option, idx) => {
                console.log(option);
                return (
                  <button
                    key={idx}
                    type="button"
                    {...register(field.Key, {
                      required: {
                        value: field.required,
                        message: `${field.label} is required`,
                      },
                    })}
                    // className={`px-4 py-2 rounded-lg shadow-md ${watch(field.Key) === option ? 'bg-pink-600 text-white' : 'bg-gray-200 text-black'} hover:bg-pink-400`}

                    className={`px-4 py-2 rounded-lg shadow-md ${
                      watch(field.Key) === option
                        ? option === "Yes"
                          ? "bg-green-400 text-white"
                          : option === "No"
                          ? "bg-red-400 text-white"
                          : "bg-gray-200 text-black"
                        : "bg-gray-200 text-black"
                    } ${
                      option === "Yes"
                        ? "hover:bg-green-400"
                        : option === "No"
                        ? "hover:bg-red-400"
                        : "hover:bg-gray-300"
                    }`}
                    onClick={() =>
                      setValue(field.Key, option, { shouldValidate: true })
                    }
                  >
                    {option}
                  </button>
                );
              })}
            </div>
            {errors[field.Key] && (
              <span className="text-red-500 text-sm mt-1">
                {errors[field.Key]?.message}
              </span>
            )}
            {field.radioText === true && (
              <>
                {field?.radioTextData?.map((textField, idx) => {
                  const selectedValue = (watch(field.Key) || "").toLowerCase();
                  const isRequired = selectedValue === "yes";
                  const isDisabled = !(selectedValue === "yes");
                  const isNo = selectedValue === "no";

                  return (
                    !isNo && (
                      <div key={idx} className="flex flex-col">
                        <label className="text-lg font-medium text-black mb-2">
                          {textField.label}
                        </label>
                        <input
                          type="text"
                          name={`${field.Key}_radiotext_${textField.Key}`}
                          disabled={isDisabled}
                          defaultValue={textField?.answer}
                          className="w-full border border-gray-300 rounded-lg p-2"
                          {...register(
                            `${field.Key}_radiotext_${textField.Key}`,
                            {
                              required: isRequired
                                ? {
                                    value: true,
                                    message: `${textField.label} is required`,
                                  }
                                : false,
                            }
                          )}
                        />
                        {errors[`${field.Key}_radiotext_${textField.Key}`] && (
                          <span className="text-red-500 text-sm mt-1">
                            {
                              errors[`${field.Key}_radiotext_${textField.Key}`]
                                ?.message
                            }
                          </span>
                        )}
                      </div>
                    )
                  );
                })}
              </>
            )}
          </div>
        );

      case "checkbox":
        return (
          <div key={field.Key} className="flex flex-col">
            <label className="text-lg font-medium text-black mb-2">
              {field.label}
            </label>
            {field.checkBoxOption.map((option, idx) => (
              <div key={idx} className="flex items-start mb-1">
                <input
                  type="checkbox"
                  id={`${field.Key}-${idx}`}
                  multiple
                  value={option}
                  // {...register(`${field.Key}`, {
                  //     validate: (selectedValues) => {
                  //         // Ensure all checkboxes are selected
                  //         return selectedValues && selectedValues.length === field.checkBoxOption.length ||
                  //             `Checkboxes must be selected.`;
                  //     },
                  // })}

                  {...register(`${field.Key}`, {
                    validate: (selectedValues) => {
                      if (field.checkBoxOption.length === 1) {
                        return selectedValues
                          ? true
                          : `This checkbox must be selected.`;
                      } else {
                        return (
                          (selectedValues &&
                            selectedValues.length ===
                              field.checkBoxOption.length) ||
                          `Checkboxes must be selected.`
                        );
                      }
                    },
                  })}
                  // {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                  className="mr-2 mt-2"
                />
                <label htmlFor={`${field.Key}-${idx}`}>{option}</label>
              </div>
            ))}
            {errors[field.Key] && (
              <span className="text-red-500 text-sm mt-1">
                {errors[field.Key]?.message}
              </span>
            )}{" "}
          </div>
        );

      case "dropdown":
        return (
          <div key={field.Key} className="flex flex-col">
            <label className="text-lg font-medium text-black mb-1">
              {field.label}
            </label>
            <select
              {...register(field.Key, {
                required: {
                  value: field.required,
                  message: `${field.label} is required`,
                },
              })}
              className="w-full border border-gray-300 rounded-lg p-2"
            >
              <option value="">Select an option</option>
              {field?.option?.map((data, idx) => (
                <option key={idx} value={data.value}>
                  {data.label}
                </option>
              ))}
            </select>
            {errors[field.Key] && (
              <span className="text-red-500 text-sm mt-1">
                {errors[field.Key]?.message}
              </span>
            )}
          </div>
        );

      case "date":
        console.log(getDetails[index]?.answer,"date....");
        
        return (
          <div key={field.Key} className="flex flex-col">
            <label className="text-lg font-medium text-black mb-1">
              {field.label}
            </label>
            {/* <input
                        type="date"
                        defaultValue={getDetails[index]?.answer || ''}
                        // defaultValue={localData?.dob}
                        // {...register(field.Key, { required: { value: field.required, message: `${field.label} is required` } })}
                        // defaultValue={new Date()?.toISOString()?.split('T')[0]}
                        className='w-full border border-gray-300 rounded-lg p-2'
                    /> */}

            <DatePicker
              {...register(field.Key, {
                required: {
                  value: field.required,
                  message: `${field.label} is required`,
                },
              })}
            //   value={
            //     getDetails[index]?.answer
            //       ? dayjs(getDetails[index].answer)
            //       : dayjs()
            //   }
            //   onChange={(date, dateString) =>
            //     handleDateChange(date, dateString, field, index)
            //   }
              // onChange={onChange}
              format={"MM/DD/YYYY"}
              className="w-full border border-gray-300 rounded-lg p-2"
            />
            {errors[field.Key] && (
              <span className="text-red-500 text-sm mt-1">
                {errors[field.Key]?.message}
              </span>
            )}
          </div>
        );

      case "sign pad":
        return (
          <div key={field.Key} className="flex flex-col">
            <label className="text-lg font-medium text-black mb-1">
              {field.label}
            </label>
            {/* <Signature
                            fieldKey={field.Key}
                            divclass="border-2 border-gray-800 md:max-w-[500px] md:max-w-[500px ] w-full h-full rounded-lg"
                            sendSing={(data) => handleSignatureData(data, field.Key)}
                            showButton={true}
                            initialData={signatureData[field.Key] || ''}
                        /> */}

            <SignaturePad
              fieldKey={field.Key}
              divclass="border-2 border-gray-800 md:max-w-[500px] md:max-w-[500px] w-full h-full rounded-lg"
              sendSing={(data) => handleSignatureData(data, field.Key)}
              showButton={true}
              initialData={signatureData[field.Key] || ""}
            />
            {errors[field.Key] && (
              <span className="text-red-500 text-sm mt-1">
                {errors[field.Key]?.message}
              </span>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const handleSignatureData = (data, fieldKey) => {
    setSignatureData((prevState) => ({
      ...prevState,
      [fieldKey]: data,
    }));
  };

  const isSubmitDisabled = async () => {
    const valid = await trigger(); // Perform form validation

    // First, check if the form is valid
    if (!valid) {
      return true; // Disable submission if the form is invalid
    }

    // Then check if any required signatures are missing
    const missingSignature = Array.from(requiredSignatures).some(
      (key) => !signatureData[key]
    );

    // Disable the submit button if any required signatures are missing
    if (missingSignature) {
      return true;
    }

    // If the form is valid and all required signatures are present, enable submission
    return false;
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      setLoading(false);
      // navigate(`/user/form`,{ replace: true });
      setTimeout(() => {
        navigate("/user/form");
      }, 1000);
      // updateUser();
    } else if (isError) {
      setState({ open: true });
    }
  }, [data, error, isSuccess, navigate, isError]);

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message) {
      return {
        backgroundColor: "red",
        color: "white",
      };
    }
    if (data?.message) {
      console.log(data?.message, "datamessage");
      return {
        backgroundColor: "green",
        color: "white",
      };
    }
    return {};
  };

  // const updateUser = async () => {
  //     try {
  //         let parseData = JSON.parse(localData.completed_forms);
  //         console.log(typeof(parseData),"parseData",typeof(parseData) === 'number')
  //         if(typeof(parseData) === 'number') {
  //             parseData = [parseData]
  //         }
  //         parseData?.push(formDetails.id);

  //         localData.completed_forms = JSON.stringify(parseData);
  //         localStorage.setItem("items", JSON.stringify(localData));

  //         const url = `${process.env.REACT_APP_BASE_URL}api/updateCustomerInfo/${localData.id}`;
  //         const response = await axios.put(url, { completed_forms: parseData }, {
  //             headers: {
  //                 'authorization': `Bearer ${localData?.token}`
  //             }
  //         });

  //         if (response.status === 200) {
  //             navigate(`/user/form`);
  //         }
  //         setLoading(false)
  //     } catch (error) {
  //         console.error('API call error:', error);
  //         setLoading(false)
  //         throw error;
  //     }
  // };

  const watchRadioValues = watch();

  return (
    <>
      <div className="container mx-auto mt-5 px-4">
        <div
          className="bg-white shadow-lg rounded-lg p-6 mb-4 border-2 border-gray-300"
          style={{ minHeight: "100vh" }}
        >
          <div className="flex flex-col md:flex-row justify-between">
            {/* <div className="flex-none w-full md:w-1/6 mb-4 md:mb-0">
                            <img className="w-full h-auto rounded-lg" src={Latan} alt="Logo" />
                        </div> */}
            <div className="text-xl font-bold flex items-center justify-between w-full ">
              <img src={Latannewlogo} width={70} height={70} />
              <img src={Latan} width={120} height={120} />
            </div>
          </div>

          <div className="flex-grow text-center py-8">
            <h1 className="text-2xl md:text-4xl font-bold text-[#003060]">
              L.A.TAN<sub>&reg;</sub> {formDetails?.form_description}
            </h1>
          </div>

          {pdfUrl ? (
            // <iframe src={pdfUrl} width="100%" style={{minHeight:'100vh'}} title="PDF Viewer"></iframe>
            <embed
              src={pdfUrl}
              en
              type="application/pdf"
              width="100%"
              style={{ minHeight: "100vh" }}
            />
          ) : null}

          {!loading && groupedFields?.length > 0 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> */}
              <div className="grid gap-4 grid-cols-1">
                {groupedFields[currentStep]?.map((field, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        field.type === "checkbox" ||
                        field.type === "sign pad" ||
                        field.type === "normal text" ||
                        field.type === "h1" ||
                        field.type === "h2" ||
                        field.type === "h3" ||
                        field.type === "h4" ||
                        field.type === "h5"
                          ? "grid-cols-1 col-span-1"
                          : ""
                      }
                    >
                      {renderFormField(field, index)}
                    </div>
                  );
                })}
              </div>

              {/* <div className="grid gap-4 grid-cols-2">
  {groupedFields[currentStep]?.map((field, index) => (
    <div
      key={index}
      className={
        ["checkbox", "sign pad", "normal text", "h1", "h2", "h3", "h4", "h5"].includes(field.type)
          ? "col-span-2" // Full width for specified types
          : "" // No extra class for default two-column layout
      }
    >
      {renderFormField(field, index)}
    </div>
  ))}
</div> */}

              {/* <div className="grid gap-4 grid-cols-2">
                {getDetails?.map((field, index) => (
                    <div
                        key={index}
                        className={
                            field.type === "checkbox" || field.type === "sign pad" || field.type === "normal text" || field.type === "h1" || field.type === "h2" || field.type === "h3" || field.type === "h4" || field.type === "h5"
                                ? "grid-cols-1 col-span-2"
                                : ""
                        }
                    >
                        {renderFormField(field, index)}
                    </div>
                ))}
            </div> */}

              <div className="mt-6 text-center flex justify-center flex space-x-4">
                {currentStep > 0 && (
                  <button
                    type="button"
                    className="bg-gray-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-gray-400 "
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                )}

                {!isLastStep && (
                  <button
                    type="button"
                    className="bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400 "
                    onClick={handleNext}
                    // disabled={isNextButtonDisabled()}
                  >
                    Next
                  </button>
                )}

                {isLastStep && (
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-400 "
                    disabled={isLoading}
                  >
                    Submit
                    {isLoading && (
                      <div className="w-4 h-4 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>
                    )}
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={state.open}
        onClose={handleClose}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        TransitionComponent={state.Transition}
        onExited={() => {
          if (isSuccess) {
            navigate("/user/form");
          }
        }}
        message={error?.response?.data?.message || data?.message}
        // message={error?.message ? error?.message : 'Something Went Wrong !'}
        // message={error?.message ? error?.message : 'Something Went Wrong !' || data?.message}

        key={state?.Transition?.name}
        autoHideDuration={3000}
      />
      {loading ? <Loader /> : null}
    </>
  );
};

export default FormDetailsByName;
