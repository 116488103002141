import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreate } from '../../hooks/Api'
import Snackbar from '@mui/material/Snackbar';
import { message } from 'antd';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
import { useFetch } from '../../hooks/Api';

const Add_Phone = () => {

  const apiname = process.env.REACT_APP_API_PHONES?.toString();

  const { mutate, data, isError, isSuccess, error, isLoading } = useCreate(apiname);
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
  const navigate = useNavigate()

  const onSubmit = (data) => {
    let finalData = {
      ...data,
    }
    mutate(finalData)
  }


 



  const [state, setState] = useState({
    open: false
  });



  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };




  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/phones");
      }, 1000);
    } else if (isError) {
      console.log(error, "isError");
      setState({ open: true });
    }
  }, [isSuccess, isError, error, data, navigate]);
  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Add Phone</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="phone">
                  Phone Number <span className='text-blue-500'> Ex: (xxx)-xxx-xxxx </span>
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                <input
                  type="text"
                  id="mobile"
                  name="phone"
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter your Phone Number"
                  {...register('phone', {
                    required: 'Mobile number is required',
                    pattern: {
                      // value: /^(?:\+?(?:[0-9\-\(\)\/.\s]){10,16}[0-9]|[0-9]{10})$/,
                      value:/^\(\d{3}\)-\d{3}-\d{4}$/,

                      message: 'Please enter a valid phone number',
                    },
                  })}
                />
                </div>
                <p style={{ color: "Red" }}>{errors?.phone?.message}</p>
              </div>


          
            </div>
            <div className='text-center'>
              <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
     

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        onExited={() => {
          if (isSuccess) {
            navigate("/layout/phones");
          }
        }}
        message={error?.response?.data?.message || data?.message || error?.message}
      />
      {
        isLoading ? (
          <Loader/>
        ):null
      }

    </>
  );
}

export default Add_Phone;
