import React, { useEffect, useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useFetchSingle, useUpdateSingle } from '../hooks/Api';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useFetch } from '../../src/hooks/Api';
import { Select } from 'antd';

const Profile = () => {

  const navigate = useNavigate()
  const localStorageData = localStorage.getItem('items')
  const tokenData = localStorageData ? JSON.parse(localStorageData) : null

  const apiname = process.env.REACT_APP_API_CHANGE_PASSWORD.toString()
  const apinameInfo = process.env.REACT_APP_API_UPDATE_USER_INFO.toString()
  const apinameUser = process.env.REACT_APP_API_USER_INFO.toString()
  // const { id } = useParams()
  const { data } = useFetchSingle(tokenData.id, apinameUser)
  const { mutate, isSuccess, isError, error, data: Updated_response, isLoading } = useUpdateSingle()

  const { register, handleSubmit, formState: { errors }, reset, setValue, clearErrors, } = useForm();

  const { register: passwordRegister, handleSubmit: handlePassowrd, formState: { errors: errorsPassowrd } } = useForm();



  const [storeValue, setStoreValue] = useState()
  const apinameStore = process.env.REACT_APP_API_STORES?.toString();
  const { data: storeData, error: storeError, isFetching: storeIsFetching, refetch: storeRefetch } = useFetch(apinameStore, 1, 100000);


  const [storeOptions, setStoreOptions] = useState([])
  useEffect(() => {
    if (storeData) {
      let data = storeData?.data?.map((e) => { return { label: e.store_name, value: e.id } })
      setStoreOptions(data)
    }
  }, [storeData])

  const onChangestore = (value) => {
    setValue('store_id', value)
    setStoreValue(value)
    clearErrors('store_id')

  };

  const onSearchstore = (value) => {
    console.log('search:', value);
  };


  const [user, setUser] = useState({
    // store_id: '',
    email: '',
    store_manager_name: '',
    // store_address: ''
  });

  useEffect(() => {
    if (data) {
      reset(data)
      setUser(data)

      const matchingStore = storeOptions?.find((e) => e.value === data?.store_id);
      setValue('store_id', matchingStore?.value)
      setStoreValue(matchingStore)
    }
  }, [data, storeOptions])


  const [password, setPassword] = useState({
    current_password: '',
    password: '',
    confirm_password: '',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const handleProfileUpdate = () => {
    // alert('Profile updated successfully!');
    if (tokenData.roles === 'admin') {
      delete user.store_manager_name
      delete user.store_address
    }
    user.store_id = storeValue
    let data = {
      apiname: apinameInfo,
      id: tokenData?.id,
      data: user
    }
    mutate(data)
  };

  const handlePasswordUpdate = () => {

    let data = {
      apiname: apiname,
      id: tokenData?.id,
      data: password
    }
    mutate(data)


  };


  const [iconChange, setIconChange] = useState("password")
  const [currentPassword, setCurrentPassword] = useState("password")
  const [newPassword, setNewPassword] = useState("password")

  const changeicon = () => {
    if (iconChange == "password") {
      setIconChange("text")
    }
    else {
      setIconChange("password")
    }
  }
  const handelCurrentPassword = () => {
    if (currentPassword === 'password') {
      setCurrentPassword("text")
    }
    else {
      setCurrentPassword("password")
    }
  }
  const handelNewPassword = () => {
    if (newPassword === 'password') {
      setNewPassword("text")
    }
    else {
      setNewPassword("password")
    }
  }


  const [state, setState] = useState({
    open: false
  });

  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/profile");
      }, 1000);
    } else if (isError) {
      console.log(error, "isError");
      setState({ open: true });
    }
  }, [isSuccess, isError, error, Updated_response, navigate]);

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.error || Updated_response?.response?.data?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (Updated_response?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };


  return (
    <>
      <div className="p-4 space-y-4">

      

        <div className="text-2xl font-bold text-gray-800">Profile</div>

        {/* Profile Information */}
        <div className="bg-white shadow rounded-lg p-4">
          <div className="text-gray-600 mb-2">User Information</div>
          <div className="space-y-4">

            <div>
              <label className="block text-gray-600">Email</label>
              <input
              disabled
                {...register('email', { required: { value: false, message: 'Email Is Required' } })}
                type="email"
                name="email"
                placeholder='Email'
                value={user?.email}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
              />
              {/* <p style={{ color: "Red" }}>{errors?.email?.message}</p> */}
              <p style={{ color: "blue" }}>{tokenData?.roles === "store"?'For email update you need to contact admin.':'For email update you need to contact Operator.'}</p>

            </div>
            {
              tokenData?.roles === 'store' ?
                <>

                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="">
                      Store Name
                    </label>
                    <Select
                      {...register('store_id', { required: { value: true, message: 'Store Is Required' } })}

                      className='w-full'
                      style={{ height: '40px' }}
                      showSearch
                      value={storeValue}
                      placeholder="Select a Store"
                      optionFilterProp="label"
                      onChange={onChangestore}
                      onSearch={onSearchstore}
                      options={storeOptions}
                    />
                    <p style={{ color: "Red" }}>{errors?.store_id?.message}</p>
                  </div>
                  <div>
                    <label className="block text-gray-600">Store Manager Name</label>
                    <input
                      {...register('store_manager_name', { required: { value: true, message: 'Store Manager Name Is Required' } })}
                      type="text"
                      name="store_manager_name"
                      placeholder='Store Manager Name'
                      // value={user.store_manager_name}
                      onChange={handleInputChange}
                      className="w-full border rounded p-2"
                    />
                    <p style={{ color: "Red" }}>{errors?.store_manager_name?.message}</p>

                  </div>
                  {/* <div>
                    <label className="block text-gray-600">Store Address</label>
                    <input
                     {...register('store_address', { required: { value: true, message: 'Store Address Is Required' } })}
                      type="text"
                      name="store_address"
                      placeholder='Store Address'
                      // value={user.store_address}
                      onChange={handleInputChange}
                      className="w-full border rounded p-2"
                    />
                                  <p style={{ color: "Red" }}>{errors?.store_address?.message}</p>

                  </div> */}

<button
              // onClick={handleProfileUpdate}
              onClick={handleSubmit(handleProfileUpdate)}
              className="bg-pink-600 text-white py-2 px-4 rounded hover:bg-pink-700"
            >
              Update Profile
            </button>

                </>
                : ''}




            
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-4">
          <div className="text-gray-600 mb-2">Change Password</div>
          <div className="space-y-4">
            <div className="relative">
              <label className="block text-gray-600">Current Password</label>
              <input
                {...passwordRegister('current_password', { required: { value: true, message: 'Current Password Is Required' } })}
                type={currentPassword ? currentPassword : "password"}
                name="current_password"
                // value={password.current_password}
                onChange={handlePasswordChange}
                className="w-full border rounded p-2 pr-10"
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3 mt-5">
                <button onClick={handelCurrentPassword} className="text-gray-600">
                  {currentPassword === "password" ? <FaRegEyeSlash /> :  <FaRegEye />}
                </button>
              </span>
              <p style={{ color: "Red" }}>{errorsPassowrd?.current_password?.message}</p>

            </div>

            <div className="relative">
              <label className="block text-gray-600">New Password</label>
              <input
                {...passwordRegister('password', { required: { value: true, message: 'Password Is Required' } })}

                type={newPassword ? newPassword : "password"}
                name="password"
                // value={password.password}
                onChange={handlePasswordChange}
                className="w-full border rounded p-2 pr-10"
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3 mt-5">
                <button onClick={handelNewPassword} className="text-gray-600">
                  {newPassword === "password" ?  <FaRegEyeSlash /> :  <FaRegEye />}
                </button>
              </span>
              <p style={{ color: "Red" }}>{errorsPassowrd?.password?.message}</p>

            </div>

            <div className="relative">
              <label className="block text-gray-600">Confirm New Password</label>
              <input
                {...passwordRegister('confirm_password', { required: { value: true, message: 'Confirm Password Is Required' } })}

                type={iconChange ? iconChange : "password"}
                name="confirm_password"
                // value={password.confirm_password}
                onChange={handlePasswordChange}
                className="w-full border rounded p-2 pr-10"
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3 mt-5">
                <button onClick={changeicon} className="text-gray-600">
                  {iconChange === "password" ?  <FaRegEyeSlash /> :  <FaRegEye />}
                </button>
              </span>
              <p style={{ color: "Red" }}>{errorsPassowrd?.confirm_password?.message}</p>

            </div>

            <button
              // onClick={handlePasswordUpdate}
              onClick={handlePassowrd(handlePasswordUpdate)}
              className="bg-pink-600 text-white py-2 px-4 rounded hover:bg-pink-700"
            >
              Change Password
            </button>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={state.open}
          onClose={handleClose}
          autoHideDuration={3000}
          ContentProps={{
            style: getSnackbarStyle(),
          }}
          onExited={() => {
            if (isSuccess) {
              navigate("/layout/profile");
            }
          }}
          message={Updated_response?.response?.data?.message || Updated_response?.message || 'An error occurred'}
        />


      </div>
    </>

  );
};

export default Profile;
