import { useForm } from "react-hook-form";
import Latan from "../../images/copy1.png";
import Latannewlogo from "../../images/sore_1.png";
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import { Snackbar } from '@mui/material';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import Loader from "../../component/UI Component/Loader";
import CustomModal from "../../component/UI Component/Modal";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const PersonalDetails = () => {

  const {userId} = useParams()
  // const {
  //   register,
  //   setValue,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();


  const store_uuid = localStorage.getItem("store_uuid")
  const form_category = localStorage.getItem("form_category")
  const form_name = localStorage.getItem("form_name")

  const localStorageData = localStorage.getItem('user')
  const tokenData = localStorageData ? JSON.parse(localStorageData) : null

  const apiname = process.env.REACT_APP_API_GET_USER?.toString();
  const apinameUpdate = process.env.REACT_APP_API_UPDATE_USER?.toString();
 const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
    const updateStoreUserDetails = await axios.put(`${process.env.REACT_APP_BASE_URL}${apinameUpdate}/${userId}`, data, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      },
    })
    console.log('>>>',updateStoreUserDetails)
    return updateStoreUserDetails.data
  })
 const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState([])
  const [state, setState] = React.useState({
    open: false
  });

  const { register, handleSubmit, setError, formState: { errors }, reset, setValue } = useForm({
    // defaultValues: {
    //   // name: userDetails.name,
    //   // email: userDetails.email,
    // }
  });

  const [birthDate,setBirthDate] = useState(null)
useEffect(() => {
    if (userDetails) {
      reset(userDetails)
      const formattedDate = dayjs(userDetails.dob).format("MM/DD/YYYY");
      setValue('dob', formattedDate); // Set the formatted date in the form
      setBirthDate(dayjs(formattedDate)); // Set the formatted date in state
    }
  }, [userDetails])


  const onSubmit = (data) => {
    // let finalData = {
    //   ...data,
    //   role: 'user',
    //   storeId:userDetails.storeId,
    //   completed_forms:userDetails.completed_forms
    // }
    mutate(data)
  }
 useEffect(() => {
    fetchData()
  }, [])
const fetchData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname}/${userId}`, {
      headers: {
          'authorization': `Bearer ${tokenData?.token}`
        }
    });
    setUserDetails(res.data);
  }
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };



  const [loading,setLoading] = useState(false)
  const [alreadyData,setAlreadyData] = useState()
  const apiname1 = process.env.REACT_APP_API_FORM_BY_NAME.toString();

  useEffect(() => {

    if(form_category === 'Release'){

      isAlreadySubmitted();
    }
  }, [form_name]);
 const isAlreadySubmitted = async () => {
    try {
      setLoading(true);
     

      try {
        setLoading(true);
        const localStorageData = localStorage.getItem("user");
        const tokenData = localStorageData
          ? JSON.parse(localStorageData)
          : null;

        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}${apiname1}/${tokenData?.id}/${store_uuid}/${form_name}`,
          {
            headers: {
              authorization: `Bearer ${tokenData?.token}`,
              "ngrok-skip-browser-warning": "1",
            },
          }
        );
        setAlreadyData(res.data.results);
        const getDetails = JSON.parse(res?.data?.results?.form_fields);
        localStorage.setItem(
          "membership_id",
          res?.data?.results?.membership_id
        );

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
      // }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

console.log('alreadyData',alreadyData,form_category)


  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });


      // if(alreadyData != undefined ){
// debugger
        if(form_category === 'Release'){
          setTimeout(() => {
              navigate(`/user/form/${userDetails?.id}/${form_name}`)
      }, 1000); 
        }else{
          setTimeout(() => {
              navigate(`/user/${userDetails?.id}/${userDetails?.store_uuid}/${form_category}`)
      }, 1000); 
        }

      // }
    


    } else if (isError) {
      setState({ open: true });
    }
  }, [userDetails, error, isSuccess,form_category]);

  const onChange = (date) => {
    // Only format if date is selected
    const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : null;
    setValue("dob", formattedDate); // Set formatted date in the form
    setBirthDate(formattedDate ? dayjs(formattedDate) : null); // Update state
};

const onClearDate = () => {
    setValue("dob", null); // Clear the form field value
    setBirthDate(null);    // Reset the state to null
};

  

  const handleLogin = () =>{
    window.location.href = 'https://www.google.com';
  }

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };
  
  return (
    <>
    {alreadyData != undefined && form_category === 'Release' ||  alreadyData == undefined && form_category != 'Release'?<>
    <div className="container mx-auto mt-5 px-4">
        <div
          className="bg-white shadow-lg rounded-lg p-6 mb-4 border-2 border-gray-300"
          style={{ minHeight: "100vh" }}
        >
        
        <div className="mb-5 text-xl font-bold flex items-center justify-between w-full">
          <img src={Latannewlogo} width={70} height={70} />
          <img src={Latan} width={120} height={120} />
        </div>

        <div className="flex-grow text-center mb-5">
          <h1 className="text-2xl md:text-4xl font-bold text-[#003060] md:mb-12">
           Personal Details
          </h1>
        </div>
        <form>
          {/* Row 1: First Name & Last Name */}
          <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">

            <div className="items-center mb-4">

              <div className="">

              <label
                className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
                htmlFor="firstname"
                >
                First Name:
              </label>
              <input
                {...register("first_name", {
                  required: "First Name is required.",
                })}
                id="first_name"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.first_name
                  ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                  }`}
                  placeholder="First Name"
                  />
                  </div>
              {errors.first_name && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.first_name.message}
                </p>
              )}
            </div>

            <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
  htmlFor="lastname"
  >
  Last Name:
</label>
              <input
                {...register("last_name", {
                  required: "Last Name is required.",
                })}
                id="last_name"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.last_name
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="Last Name"
              />
                  </div>
              {errors.last_name && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.last_name.message}
                </p>
              )}
          </div>
          </div>

          {/* Row 2: Email & Mobile */}
          <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
  htmlFor="email"
  >
  Email:
</label>
              <input
              disabled
                {...register("email")}
                id="email"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.email
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="Email Address"
              />
                  </div>
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 mb-1 font-bold"
  htmlFor="mobile"
  >
  Mobile: <span className='text-blue-500'>Ex: (xxx)-xxx-xxxx</span>

</label>
              <input
                {...register("mobile", {
                  required: "Phone Number is required.",
                  pattern: {
                    value: /^\(\d{3}\)-\d{3}-\d{4}$/,
                  message: 'Please enter a valid phone number',
                },
                })}
                id="mobile"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.mobile
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="Phone Number"
              />
                  </div>
              {errors.mobile && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.mobile.message}
                </p>
              )}
          </div>
          </div>

          {/* Row 3: Birth Date & Address 1 */}
          <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-full mb-1 font-bold"
  htmlFor="dob"
  >
  Date Of Birth:
</label>
           



<DatePicker
    {...register("dob", {
        required: "Date of birth is required",
    })}
    value={birthDate ? dayjs(birthDate, "MM/DD/YYYY") : null} // Parse to "MM/DD/YYYY" format
    onChange={onChange}
    onClear={onClearDate}
    format={"MM/DD/YYYY"}
    className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
        errors.dob ? "border-red-500 focus:ring-red-300" : "focus:ring-pink-200"
    }`}
/>
</div>
              {errors.dob && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.dob.message}
                </p>
              )}
            </div>

            <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
  htmlFor="address1"
  >
  Address 1:
</label>
              <input
                {...register("address1", {
                  required: "Address 1 is required.",
                })}
                id="address1"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.address1
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="Address 1"
              />
              
              </div>
              {errors.address1 && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.address1.message}
                </p>
              )}
            </div>
          </div>

          {/* Row 4: Address 2 & State */}
          <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
  htmlFor="address2"
  >
  Address 2:
</label>
              <input
                {...register("address2", {
                  required: "Address 2 is required.",
                })}
                id="address2"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.address2
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="Address 2"
              />
                  </div>
              {errors.address2 && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.address2.message}
                </p>
              )}
            </div>

            <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
  htmlFor="state"
  >
  State:
</label>
              <input
                {...register("state", {
                  required: "State is required.",
                })}
                id="state"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.state
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="State"
              />
                  </div>
              {errors.state && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.state.message}
                </p>
              )}
            </div>
          </div>

          {/* Row 5: City & Zip */}
          <div className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
  htmlFor="city"
  >
  City:
</label>
              <input
                {...register("city", {
                  required: "City is required.",
                })}
                id="city"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.city
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="City"
              />
                  </div>
              {errors.city && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.city.message}
                </p>
              )}
            </div>

            <div className="items-center mb-4">

<div className="">

<label
  className="block text-gray-700 mr-3 w-24 mb-1 font-bold"
  htmlFor="zip"
  >
  Zip:
</label>
              <input
                {...register("zip", {
                  required: "Zip is required.",
                })}
                id="zip"
                className={`border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 transition ${
                  errors.zip
                    ? "border-red-500 focus:ring-red-300"
                    : "focus:ring-pink-200"
                }`}
                placeholder="Zip"
              />
                  </div>
              {errors.zip && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.zip.message}
                </p>
              )}
            </div>
          </div>

          {/* Next Button */}

          <div className="flex justify-center items-center">

          <button
          onClick={handleSubmit(onSubmit)}
                    type="button"
                    className="bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400 "
                  >
                    Next
                  </button>
          </div>

        </form>
      </div>

      
    </div>
    </>:null}
    {loading ? <Loader /> : null}
    <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        TransitionComponent={state.Transition}
        message={error?.response?.data?.message || data?.message}
        key={state?.Transition?.name}
        autoHideDuration={3000}
      />
          {alreadyData == undefined && form_category == 'Release' ||  alreadyData != undefined && form_category != 'Release' ? <>
          
            <CustomModal
                open={alreadyData == undefined && form_category == 'Release' ||  alreadyData != undefined && form_category != 'Release'}
                // onClose={handleNo}
                // onYes={handleYes}
                Ok={handleLogin}
                text="You already submitted this form."
            />
          </>:null}
    </>
  );
};

export default PersonalDetails;
