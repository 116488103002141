import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { MdDeleteForever } from 'react-icons/md';
import CustomModal from '../../component/UI Component/Modal';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import CommonTable from '../../component/Common table/Table';
import { Tooltip } from 'antd';

const List_Register_Store_Manager = () => {
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_API_STORE_MANAGERS.toString();
    const deleteapiname = process.env.REACT_APP_API_SM_UPDATE_DELETE.toString();
    const [response, setResponse] = useState()
    const [state, setState] = useState({
        open: false
    });

    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const itemsPerPage = 10;

    const query = new URLSearchParams({
        search: inputValue !== "" ? inputValue : "",
        page: currentPage,
        limit: 10,
    }).toString();
    const { data, error, isFetching, refetch } = useFetch(`${apiname}?${query}`);

    useEffect(() => {
        if (isFetching && error === null) {
            refetch();
        }
    }, [inputValue, currentPage]);

    if (!data) return null;

    const totalPages = Math.ceil(data?.totalRows / itemsPerPage);

    const onSearchChange = (event, key) => {
        setInputValue(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const removeTheStoreUser = (userId) => {
        setUserId(userId)
        setOpen(true)
    }

    const handleYes = () => {
        setOpen(false);
        deleteUser()
    };

    const handleNo = () => {
        setOpen(false);
    };
    const handleClose = () => {
        setState(prevState => ({ ...prevState, open: false }));
    };
    const getSnackbarStyle = () => {
        return response?.status === 204
            ? { backgroundColor: 'red', color: 'white' }
            : { backgroundColor: 'green', color: 'white' };
    };
    const deleteUser = async () => {
        const localData = JSON.parse(localStorage.getItem("items"))
        const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}${deleteapiname}/${userId}`, {
            headers: {
                'authorization': `Bearer ${localData?.token}`
            }
        })
        setResponse(res);
        setState({ open: true });
        await refetch();
    }


    const headers = [
        {key:"id",value:'Sr No.'},
        {key:'store_name',value:'Store Name'},
        {key:'store_manager_name',value:'Store Manager Name'},
        {key:'address',value:'Store Address'},
        {key:'store_uuid',value:'Store ID'},
        {key:'email',value:'Email'},
        {key:'action',value:'Action'},
    ];
    const startIndex = (currentPage - 1) * 10;

    const rows = data?.data?.map((e, index) => {

        return {
            id: e.id,
            index: startIndex + index + 1,
            store_name: e?.store_name,
            store_manager_name: e?.store_manager_name,
            address: <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{e.address}</p>} placement="top" arrow >
                <div>
                    {e.address?.length >= 25 ? e.address?.slice(0, 22)?.concat('...') : e.address}
                </div>
            </Tooltip>,
            store_uuid: e?.store_uuid,
            email: e?.email,
        };
    });

    const handleAddClick = () => {
        navigate('/layout/Addstore');
    };

    return (
        <>

            {(data === "There is no store manager yet!" || error) ? (
                <p className='flex justify-center text-3xl'>{error ? error.message : "No Store Manager"}</p>
            ) : (
                <>
                    <div>
                        <CommonTable
                            ButtonLabel={'Add Store Manager'}
                            showAddButton={true}
                            showSearch={false}
                            headers={headers}
                            rows={rows || []}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            onEditClick={(id) => navigate(`/layout/Editstore/${id}`)}
                            apiname={apiname}
                            deleteapiname={deleteapiname}
                            onAddClick={handleAddClick}
                            refetch={refetch}
                            searchQuery={inputValue}
                            onSearchChange={onSearchChange}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default List_Register_Store_Manager;

