import React, { useEffect } from 'react';
import Emailimg from '../../images/Email.png'
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import  Snackbar  from '@mui/material/Snackbar';
// import authService from '../../service/auth.service;
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

const Login_User = () => {
  const storeId = localStorage.getItem("store_uuid")
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
  const apiname = process.env.REACT_APP_API_SEND_OTP.toString();
  const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
    const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}${apiname}`, data)    
    return postapicall.data
  })
  const navigate = useNavigate()

  const onSubmit = (data) => {
    const submitData = {
      ...data,
      store_uuid:storeId
    }
    mutate(submitData)
  }

  const [state, setState] = React.useState({
    open: false
  });
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  useEffect(()=>{
    localStorage.removeItem('items')
  },[])

  useEffect(()=>{
    if (isSuccess && storeId) {
      const email = getValues('email')
      const mobile = getValues('mobile')
      localStorage.setItem("userEmail",email)
      localStorage.setItem("userMobile",mobile)
      localStorage.setItem('user',JSON.stringify(data))
      // navigate(`/user-otp`)
      setState({open:true})
      setTimeout(() => {
        navigate(`/user-otp`);
      }, 1000); 
    }
    else if(isError){
      console.log(isError,"isError");
      setState({open:true})
    }
  },[data,error,isSuccess,navigate])
    
  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      return {
        backgroundColor: 'red', 
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green', 
        color: 'white',
      };
    }
    return {};
  };

  // const [dob, setDob] = useState(
  //   new Date().toLocaleDateString('en-US') // MM/DD/YYYY format
  // );


  const onChange = (date, dateString) => {
    setValue('dob',dateString)
  };


  useEffect(() => {
    // Set the default value to today's date in MM/DD/YYYY format
    setValue('dob', dayjs().format('MM/DD/YYYY'));
  }, [setValue]);


  
  return (
    <>
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row max-w-4xl p-6 rounded-lg ">

        <div className="hidden md:flex md:w-1/2 items-center justify-center p-4">

          <img src={Emailimg} alt='' />
        </div>

        <div className="w-full md:w-1/2 p-6  bg-white shadow-lg">
          <h2 className="text-2xl font-bold text-center mb-6">Login </h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="otp">
                Mobile Number
              </label>
              <div className="flex items-center border border-gray-300 rounded-md p-2">
              <svg className="h-8 w-8 text-gray-400" viewBox="-13.32 0 68.514 68.514" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Group_62" data-name="Group 62" transform="translate(-815.597 -920.238)"> <g id="Group_59" data-name="Group 59"> <path id="Path_53" data-name="Path 53" d="M852.706,988.752H820.363a4.773,4.773,0,0,1-4.766-4.767v-58.98a4.773,4.773,0,0,1,4.766-4.767h32.343a4.773,4.773,0,0,1,4.767,4.767v58.98A4.773,4.773,0,0,1,852.706,988.752Zm-32.343-65.514a1.769,1.769,0,0,0-1.766,1.767v58.98a1.769,1.769,0,0,0,1.766,1.767h32.343a1.769,1.769,0,0,0,1.767-1.767v-58.98a1.769,1.769,0,0,0-1.767-1.767Z" fill="#727883"></path> </g> <g id="Group_60" data-name="Group 60"> <rect id="Rectangle_35" data-name="Rectangle 35" width="26.5" height="43.764" rx="2.058" transform="translate(823.285 929.072)" fill="#727883"></rect> </g> <g id="Group_61" data-name="Group 61"> <circle id="Ellipse_76" data-name="Ellipse 76" cx="3.699" cy="3.699" r="3.699" transform="translate(832.836 975.706)" fill="#727883"></circle> </g> </g> </g></svg>                 
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" id="calender">
  <path d="M34 24H24v10h10V24zM32 2v4H16V2h-4v4h-2c-2.21 0-3.98 1.79-3.98 4L6 38c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V10c0-2.21-1.79-4-4-4h-2V2h-4zm6 36H10V16h28v22z"></path>
  <path fill="none" d="M0 0h48v48H0z"></path>
</svg> */}


                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  maxLength={15}
                  minLength={10}
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter your Mobile Number"
                  {...register('mobile', {
                    required: 'Mobile number is required',
                    pattern: {
                      value: /^(\+?1?\s?(\d{3}|\(\d{3}\))[-.\s]?\d{3}[-.\s]?\d{4}|[6-9]\d{9})$/,
                      message: 'Please enter a valid mobile number',
                    },
                  })}
                />
              </div>
              <p style={{ color: "Red" }}>{errors?.mobile?.message}</p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="otp">
                Email ID
              </label>
              <div className="flex items-center border border-gray-300 rounded-md p-2">
                <svg class="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>

                <input
                  type="text"
                  id="email"
                  name="email"
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter your Email"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                      message: 'Please enter a valid email',
                    },
                  })}
                  onChange={(e) => {
                    e.target.value = e.target.value.toLowerCase();
                    e.target.form.dispatchEvent(new Event('input', { bubbles: true }));
                  }}
                />
              </div>
              <p style={{ color: "Red" }}>{errors?.email?.message}</p>
            </div>


            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700" htmlFor="otp">
                Date of Birth
              </label>
              <div className="flex items-center border border-gray-300 rounded-md p-2">
              <svg className='h-8 w-8' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#727883"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 10V7C20 5.89543 19.1046 5 18 5H6C4.89543 5 4 5.89543 4 7V10M20 10V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V10M20 10H4M8 3V7M16 3V7" stroke="#727883" stroke-width="2" stroke-linecap="round"></path> <rect x="6" y="12" width="3" height="3" rx="0.5" fill="#727883"></rect> <rect x="10.5" y="12" width="3" height="3" rx="0.5" fill="#727883"></rect> <rect x="15" y="12" width="3" height="3" rx="0.5" fill="#727883"></rect> </g></svg>                {/* <input
                  type="date"
                  id="dob"
                  name="dob"
                  
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter your dob"
                  {...register('dob', {
                    required: 'Date of birth is required',
                   
                  })}
                  defaultValue={new Date()?.toLocaleDateString('en-US')?.split('T')[0]}
                  onChange={(e) => {
                    e.target.value = e.target.value.toLowerCase();
                    e.target.form.dispatchEvent(new Event('input', { bubbles: true }));
                  }}
                /> */}
                <DatePicker 
                  {...register('dob', {
                  required: 'Date of birth is required',
                    
                })}
                removeIcon={true}
                suffixIcon={false}
                defaultValue={dayjs()}
                // value={dayjs()}
                onChange={onChange}  
                format={'MM/DD/YYYY'}
               
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"

                />
              </div>
              <p style={{ color: "Red" }}>{errors?.dob?.message}</p>
            </div>
            <button type='submit' className="w-full block text-center bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700">

              <div className='flex justify-center'>

                <div className='mx-2'>
                  <span> Get OTP</span>
                </div>

                <div>
                  {isLoading && <div className="w-6 h-6 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
                </div>

              </div>

            </button>

          </form>
        </div>
      </div>
    </div>
     <Snackbar
     anchorOrigin={{ vertical:'top', horizontal:'right' }}
       open={state.open}
       onClose={handleClose}
       ContentProps={{
        style: getSnackbarStyle(),
      }}
      onExited={() => {
        if (state.open && isSuccess) {
          navigate("/user-otp");
        }
      }}
       TransitionComponent={state.Transition}
       message={error?.response?.data?.message || data?.message  || error?.message}
       key={state?.Transition?.name}
       autoHideDuration={3000}
       />
    </>
  );
};

export default Login_User;

