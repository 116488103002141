import React, { useRef, useState, useEffect, useCallback } from "react";

const SignaturePad = ({
  onSave,
  sendSing = () => {},
  fieldKey,
  showButton,
  initialData,
}) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [data, setData] = useState(initialData || "");
  const [signDone, setSignDone] = useState(!!initialData);
  const emptyCanvasString =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAADICAYAAAAeGRPoAAAAAXNSR0IArs4c6QAACHNJREFUeF7t1QENAAAIwzDwbxodLMXBe5LvOAIECBAgQOC9wL5PIAABAgQIECAwBt0TECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBAy6HyBAgAABAgEBgx4oUQQCBAgQIGDQ/QABAgQIEAgIGPRAiSIQIECAAAGD7gcIECBAgEBAwKAHShSBAAECBAgYdD9AgAABAgQCAgY9UKIIBAgQIEDAoPsBAgQIECAQEDDogRJFIECAAAECBt0PECBAgACBgIBBD5QoAgECBAgQMOh+gAABAgQIBAQMeqBEEQgQIECAgEH3AwQIECBAICBg0AMlikCAAAECBA71UQDJ6RV1xQAAAABJRU5ErkJggg==";

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 500;
    canvas.height = 200;
    const context = canvas.getContext("2d");
    context.strokeStyle = "black";
    context.lineWidth = 2;
    context.lineCap = "round";
    context.lineJoin = "round";

    if (initialData) {
      const img = new Image();
      img.src = initialData;
      img.onload = () => context.drawImage(img, 0, 0);
    }
  }, [initialData]);

  const startDrawing = useCallback((event) => {
    event.preventDefault(); // Prevent default touch behavior
    setIsDrawing(true);

    const { offsetX, offsetY } = event.nativeEvent.touches
      ? getTouchPos(event)
      : event.nativeEvent;
    const context = canvasRef.current.getContext("2d");
    context.beginPath();
    context.moveTo(offsetX, offsetY);
  }, []);

  const draw = useCallback(
    (event) => {
      if (!isDrawing) return;
      event.preventDefault();

      const { offsetX, offsetY } = event.nativeEvent.touches
        ? getTouchPos(event)
        : event.nativeEvent;
      const context = canvasRef.current.getContext("2d");
      context.lineTo(offsetX, offsetY);
      context.stroke();
    },
    [isDrawing]
  );

  const endDrawing = useCallback(() => {
    setIsDrawing(false);
    const signatureData = canvasRef.current.toDataURL();
    setSignDone(true);
    setData(signatureData);
  }, []);

  const getTouchPos = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    return {
      offsetX: event.touches[0].clientX - rect.left + 60,
      offsetY: event.touches[0].clientY - rect.top + 20,
    };
  };

  const clearCanvas = useCallback(() => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setSignDone(false);
    setData("");
    sendSing("", fieldKey);
  }, [fieldKey, sendSing]);

  useEffect(() => {
    if (signDone && data !==emptyCanvasString) {
      sendSing(data, fieldKey);
      setData(data);
    }
  }, [data,fieldKey]);
  return (
    <div>
      <div className="flex flex-col font-serif justify-center items-center m-auto">
        <div className="m-5">
          <div className="flex justify-center items-center flex-col sigCanvas bg-slate-200 shadow-lg shadow-slate-500">
            <canvas
              ref={canvasRef}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={endDrawing}
              onMouseLeave={endDrawing}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={endDrawing}
              style={{
                cursor: "crosshair",
                width: "100%",
                touchAction: "none",
              }}
            />
          </div>
       
          {showButton && signDone && (
            <div className="flex space-x-4 mt-2 justify-center">
              <button
                type="button"
                className="btnfocus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                onClick={clearCanvas}
              >
                <label>Clear</label>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignaturePad;
