import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreate } from '../../hooks/Api'
import Snackbar from '@mui/material/Snackbar';
import { message } from 'antd';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
import { useFetch } from '../../hooks/Api';

const Add_Zipcode = () => {

  const apiname = process.env.REACT_APP_API_ZIPCODES?.toString();
  const apinameState = process.env.REACT_APP_API_CITIES?.toString();

  const { mutate, data, isError, isSuccess, error, isLoading } = useCreate(apiname);
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
  const navigate = useNavigate()

  const onSubmit = (data) => {
    let finalData = {
      ...data,
    }
    mutate(finalData)
  }


  const { data:stateData, error:stateError, isFetching:stateIsFetching, refetch:stateRefetch } = useFetch(apinameState);


  const [stateOptions,setStateOptions] = useState([])
  useEffect(()=>{
    if(stateData){
      let data = stateData?.data?.map((e)=>{return {label:e.city_name, value:e.id}})
      setStateOptions(data)
    }
  },[stateData])



  const [state, setState] = useState({
    open: false
  });

  // useEffect(() => {
  //   if (isSuccess) {
  //     setState({ open: true });
  //     setTimeout(() => {
  //       navigate("/layout/liststore");
  //     }, 1000);
  //   } else if (isError) {
  //     console.log(error, "isError");
  //     setState({ open: true });
  //   }
  // }, [isSuccess, isError, error, data, navigate]);

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };

  const onChange = (value) => {
    // console.log(`selected ${value}`);
    setValue('city_id',value)
    clearErrors('city_id')
  };
  
  const onSearch = (value) => {
    console.log('search:', value);
  };


  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/zipcodes");
      }, 1000);
    } else if (isError) {
      console.log(error, "isError");
      setState({ open: true });
    }
  }, [isSuccess, isError, error, data, navigate]);
console.log('error',error)
  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Zipcode</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="storeName">
                  City Name
                </label>
                <Select
                                    {...register('city_id', { required: { value: true, message: 'City Name Is Required' } })}

                className='w-full h-8.5'
    showSearch
    placeholder="Select a person"
    optionFilterProp="label"
    onChange={onChange}
    onSearch={onSearch}
    options={stateOptions}
    />
                <p style={{ color: "Red" }}>{errors?.city_id?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="StoreManagerName">
                  Zip Code
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  {/* <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg> */}
                  <input
                    {...register('zip_code', { required: { value: true, message: 'Zip Code Is Required' } })}
                    type="text"
                    id="StoreManagerName"
                    className="flex-1 border border-none focus:border focus:border-none"
                    placeholder="Zip Code"
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.zip_code?.message}</p>
              </div>


          
            </div>
            <div className='text-center'>
              <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
      {/* <Snackbar
      anchorOrigin={{ vertical:'top', horizontal:'right' }}
      open={state.open}
      onClose={handleClose}
      TransitionComponent={state.Transition}
      message={error?.response?.data?.message}
      key={state?.Transition?.name}
      autoHideDuration={3000}
      /> */}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        onExited={() => {
          if (isSuccess) {
            navigate("/layout/zipcodes");
          }
        }}
        message={error?.message}
      />
      {
        isLoading ? (
          <Loader/>
        ):null
      }

    </>
  );
}

export default Add_Zipcode;
