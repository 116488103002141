import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../hooks/Api';
import prev_image from '../../../images/image_prev1.png'
import { Pagination, Stack } from '@mui/material';
import Loader from '../../../component/UI Component/Loader';

const Forms = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const query = new URLSearchParams({
    search: inputValue !== "" ? inputValue : "",
    page: currentPage,
    limit: 10,
  }).toString();
  const apiname = process.env.REACT_APP_API_FORMS.toString();
  const { data, error,isFetching, refetch } = useFetch(`${apiname}?${query}`)

  const itemsPerPage = 10;
  const totalPages = Math.ceil(data?.totalRows / itemsPerPage);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if(isFetching && error === null){
      refetch();
  }
  }, [inputValue, currentPage]);

  return (

    <div className='p-2 space-y-4'>
      <div className='flex justify-end mb-2'>
        <button className="w-fit p-2 bg-pink-600 text-white rounded-md hover:bg-pink-700" onClick={() => navigate('/layout/addForm')}>
          Add Form
        </button>
      </div>
      <div className="bg-white shadow rounded-lg p-3 w-full">
        <div className="text-gray-600 mb-2 text-lg font-semibold">Forms</div>
        {data === null && !isFetching ? (
          <p className='flex justify-center text-2xl'>No Forms found</p>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <input
                type='search'
                value={inputValue}
                onChange={handleInputChange}
                className='flex w-96 border border-gray-300 rounded-lg p-2 ml-auto'
                placeholder={`Search form`}
              />
            </div>
            {
              (data === 'No data found!' || error) ? (
                <>
                  <p className='flex justify-center text-3xl'>{error ? error.message : "No forms found."}</p>
                </>
              ) : (
                <div className='grid xs:grid-cols-1 sm:grid-cols-2 medium:grid-cols-3 lg:grid-cols-4 lg:gap-x-12 medium:gap-6 sm:gap-6 gap-4 mt-5'>
                  {
                    data?.data?.map((e) => (
                      <div
                        key={e.id}
                        className='relative border-8 border-pink-200 hover:border-pink-500 h-60 rounded-md shadow-lg overflow-hidden cursor-pointer transition-all duration-300'
                        onClick={() => navigate(`/layout/editForm/${e.id}`)}
                      >
                        {/* Blurred Background Image */}
                        <div
                          className='absolute inset-0 bg-cover bg-center blur-sm'
                          style={{ backgroundImage: `url(${prev_image})` }}
                        ></div>

                        {/* Content Overlay */}
                        <div className='absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-between p-4'>
                          <p className='text-orange-300 text-2xl font-bold h-24 w-full flex items-center justify-center text-center'>
                            {e?.form_name}
                          </p>
                          <p className='text-white h-12 w-full flex items-center justify-center text-center'>
                            {e?.form_description}
                          </p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )}
            <div className="flex justify-end mt-4">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  // color="primary"
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    '& .MuiPaginationItem-root': {
                      color: '#DB2777',
                      borderColor: '#DB2777',
                    },
                    '& .Mui-selected': {
                      backgroundColor: '#DB2777',
                      color: '#ffffff',
                    },
                    '& .MuiPaginationItem-ellipsis': {
                      // color: '#DB2777',
                    },
                  }}
                />
              </Stack>
            </div>

            {
        isFetching ? (
          <Loader/>
        ):null
      }
          </>

        )}
      </div>
    </div>
  )
}

export default Forms;