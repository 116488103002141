import React from 'react';
import Card from '../UI Component/Card';
import { MenuItem, Select, Switch } from '@mui/material';
import Input from '../UI Component/Input';
import Signature from '../signpad';
import { DatePicker, Space } from 'antd';

const FormSection = ({
    key,
    index,
    formData,
    handleRadioAddOption,
    handleRadioRemoveOption,
    handleAddOption,
    handleRemoveOption,
    handleRemoveField,
    handleDropdownChange,
    handleRemoveDropDown,
    handleRemoveRadioTL,
    handleAddDDOption,
    handleAddRadioTL,
    register,
    errors,
    unregister,
    handleInputChange,
    control,
    addSections
}) => {
    const { type, radioOptions, checkBoxOption, required, dropdownOption, label, radioText, Key, radioKey, radioLabel } = formData;

    const registers = register

    const handleAddSectionClick = () => {
        addSections(index);
    };


    const Heading = ['h1', 'h2', 'h3', 'h4', 'h5' , "normal text"]
  
    return (

        <div className='flex relative w-full'>

            <Card className="!shadow-lg !shadow-pink-200 w-full">
                <div className="flex justify-between flex-col sm:flex-row">
                    {formData?.type === 'button' && <>
                        <Input
                            type="text"
                            id={`label-${index}`}
                            name={`formData.${index}.label`}
                            defaultValue={label}
                            className="!bg-transparent"
                            divClass={"order-2 sm:order-1 w-full sm:w-[50%]"}
                            onChange={(e) => handleInputChange(index, 'label', e.target.value)}
                            register={registers}
                            error={errors?.formData?.[index]?.label?.message}
                            label={"Label"}
                            placeholder={"Label"}
                            key={key}
                        />
                    </>}


                    {formData?.type !== 'button' && <>
                        <Input
                            type="text"
                            id={`label-${index}`}
                            name={`formData.${index}.label`}
                            defaultValue={label}
                            className="!bg-transparent"
                            divClass={"order-2 sm:order-1 w-full sm:w-[50%]"}
                            onChange={(e) => handleInputChange(index, 'label', e.target.value)}
                            register={registers}
                            error={errors?.formData?.[index]?.label?.message}
                            label={Heading.includes(formData?.type) ? "Text" : "Question"}
                            placeholder={
                                Heading.includes(formData?.type) ? "Text" : "Question"
                            }
                            key={key}
                        />
                    </>}


                    {formData?.type !== 'button' && !Heading.includes(formData?.type) &&
                     <>
                        <Input
                            type="text"
                            id={`Key-${index}`}
                            name={`formData.${index}.Key`}
                            defaultValue={Key}
                            className="!bg-transparent"
                            divClass={"order-2 sm:order-1 sm:ml-2 w-full sm:w-[25%]"}
                            onChange={(e) => handleInputChange(index, 'Key', e.target.value)}
                            register={registers}
                            error={errors?.formData?.[index]?.Key?.message}
                            label={"Key"}
                            placeholder={"Key"}
                            key={key}
                        />
</>}
                    <Select
                        key={key}
                        defaultValue={type}
                        label="Short Answer"
                        className="ml-1 h-9 mt-2 sm:mt-0 order-1 sm:w-[25%] w-full"
                        name={`formData.${index}.type`}
                        {...register(`formData.${index}.type`)}
                        onChange={(e) => handleInputChange(index, 'type', e?.target?.value)}
                    >
                        <MenuItem value="short">Short Answer</MenuItem>
                        <MenuItem value="h1">H1</MenuItem>
                        <MenuItem value="h2">H2</MenuItem>
                        <MenuItem value="h3">H3</MenuItem>
                        <MenuItem value="h4">H4</MenuItem>
                        <MenuItem value="h5">H5</MenuItem>
                        <MenuItem value="normal text">Normal Text</MenuItem>
                        <MenuItem value="paragraph">Paragraph</MenuItem>
                        <MenuItem value="radio">Radio</MenuItem>
                        <MenuItem value="checkbox">CheckBoxes</MenuItem>
                        <MenuItem value="dropdown">Dropdown</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                        <MenuItem value="sign pad">Sign pad</MenuItem>
                        <MenuItem value="button">Button</MenuItem>
                        
                    </Select>
                </div>

                {formData.type === "short" && (
                    <input
                        type="text"
                        id={`short-${index}`}
                        className="!bg-transparent !border-dotted p-3 border-b-2 border-black outline-0 w-full"
                        value={"Short answer text"}
                        disabled={true}
                        label={"Question"}
                    />
                )}


{formData.type === "h1" && (
                    <textarea
                        type="text"
                        id={`h1-${index}`}
                        name={`h1-${index}`}
                        onChange={(e) => handleInputChange(index, "h1", e)}
                        className="!bg-transparent !border-dotted border-b-black border-b-2 outline-0 mt-2 w-full"
                        disabled={true}
                    />
                )}

                {formData.type === "h2" && (
                    <textarea
                        type="text"
                        id={`h2-${index}`}
                        name={`h2-${index}`}
                        onChange={(e) => handleInputChange(index, "h2", e)}
                        className="!bg-transparent !border-dotted border-b-black border-b-2 outline-0 mt-2 w-full"
                        disabled={true}
                    />
                )}
                {formData.type === "h3" && (
                    <textarea
                        type="text"
                        id={`h3-${index}`}
                        name={`h3-${index}`}
                        onChange={(e) => handleInputChange(index, "h3", e)}
                        className="!bg-transparent !border-dotted border-b-black border-b-2 outline-0 mt-2 w-full"
                        disabled={true}
                    />
                )}
                {formData.type === "h4" && (
                    <textarea
                        type="text"
                        id={`h4-${index}`}
                        name={`h4-${index}`}
                        onChange={(e) => handleInputChange(index, "h4", e)}
                        className="!bg-transparent !border-dotted border-b-black border-b-2 outline-0 mt-2 w-full"
                        disabled={true}
                    />
                )}
                {formData.type === "h5" && (
                    <textarea
                        type="text"
                        id={`h5-${index}`}
                        name={`h5-${index}`}
                        onChange={(e) => handleInputChange(index, "h5", e)}
                        className="!bg-transparent !border-dotted border-b-black border-b-2 outline-0 mt-2 w-full"
                        disabled={true}
                    />
                )}
                {formData.type === "normal text" && (
                    <textarea
                        type="text"
                        id={`normal text-${index}`}
                        name={`normal text-${index}`}
                        onChange={(e) => handleInputChange(index, "normal text", e)}
                        className="!bg-transparent !border-dotted border-b-black border-b-2 outline-0 mt-2 w-full"
                        disabled={true}
                    />
                )}
                {formData.type === "paragraph" && (
                    <textarea
                        id={`paragraph-${index}`}
                        name={`paragraph-${index}`}
                        value={"Long answer text"}
                        onChange={(e) => handleInputChange(index, 'paragraph', e)}
                        className="!bg-transparent !border-dotted border-b-black border-b-2 outline-0 mt-2 w-full"
                        disabled={true}
                    />
                )}

                {formData.type === 'radio' && (
                    <div className="flex flex-col mt-4">
                        {radioOptions?.map((option, idx) => (
                            <div key={idx} className="flex bg-transparent items-center mb-2">

                                <svg className='h-8 w-8 text-white-500 inline' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#080341" />
                                    <circle cx="12" cy="12" r="5.25" fill="#080341" />
                                </svg>
                                <Input
                                    type="text"
                                    id={`radiooption-${index}-${idx}`}
                                    name={`formData.${index}.radioOptions.${idx}`}
                                    className="!bg-transparent w-full"
                                    defaultValue={option}
                                    divClass={"w-full"}
                                    register={registers}
                                    label={"Option value"}
                                    placeholder={"Option value"}
                                    error={errors?.formData?.[index]?.radioOptions?.[idx]?.message}
                                />
                                <button type="button" onClick={() => {
                                    handleRadioRemoveOption(index, idx)
                                    unregister(`radioOption.${index}.${idx}`)
                                }} className="ml-2">
                                    <svg className="h-8 w-8 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={() => handleRadioAddOption(index)} className="flex items-center mt-2">
                            <svg className="h-8 w-8 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            Add other option or Add more
                        </button>
                        <span>
                            <label>radio with text</label>
                            <Switch
                                label="radioText"
                                onClick={(e) => { handleInputChange(index, 'radioText', e?.target?.checked) }}
                                name={`formData.${index}.radioText`}
                                {...register(`formData.${index}.radioText`)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                checked={radioText}
                            />
                            {
                                radioText && (
                                    <>
                                        {formData?.radioTextData?.map((data, idx) => (
                                            <div key={idx} className="flex sm:flex-row flex-col bg-transparent mb-2">
                                                <Input
                                                    type="text"
                                                    id={`radioLabel-${index}-${idx}`}
                                                    name={`formData.${index}.radioTextData.${idx}.label`}
                                                    className="!bg-transparent w-full"
                                                    defaultValue={data.label}
                                                    divClass={"w-full sm:w-[50%] mr-2"}
                                                    register={registers}
                                                    onChange={(e) => handleInputChange(index, 'radioTextData', { ...data, label: e.target.value }, idx)}
                                                    label={"Radio Question"}
                                                    placeholder={"Radio Question"}
                                                    error={errors?.formData?.[index]?.radioTextData?.[idx]?.label?.message}
                                                />
                                                <Input
                                                    type="text"
                                                    id={`radioLabel-${index}-${idx}`}
                                                    name={`formData.${index}.radioTextData.${idx}.Key`}
                                                    className="!bg-transparent w-full"
                                                    defaultValue={data.Key}
                                                    divClass={"w-full sm:w-[46%] ml-2"}
                                                    register={registers}
                                                    onChange={(e) => handleInputChange(index, 'radioTextData', { ...data, Key: e.target.value }, idx)}
                                                    label={"Key"}
                                                    placeholder={"Key"}
                                                    error={errors?.formData?.[index]?.radioTextData?.[idx]?.Key?.message}
                                                />
                                                <button type="button" onClick={() => handleRemoveRadioTL(index, idx)} className="mt-2 text-red-500">
                                                    <svg className="h-6 w-6 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                        <button type="button" onClick={() => handleAddRadioTL(index)} className="flex items-center mt-2">
                                            <svg className="h-8 w-8 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                                                <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                            Add other option or Add more
                                        </button>
                                    </>
                                )}
                        </span>
                    </div>
                )}


                {formData.type === 'checkbox' && (
                    <div className="flex flex-col mt-4">
                        {checkBoxOption?.map((option, idx) => (
                            <div key={idx} className="flex bg-transparent items-center mb-2">

                                <svg className="h-6 w-6 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Interface / Checkbox_Check">
                                        <path id="Vector" d="M8 12L11 15L16 9M4 16.8002V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2842 4.21799 18.9079C4 18.4801 4 17.9203 4 16.8002Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                </svg>
                                <Input
                                    type="text"
                                    id={`checkboxoption-${index}-${idx}`}
                                    name={`formData.${index}.checkBoxOption.${idx}`}
                                    className="!bg-transparent w-full"
                                    defaultValue={option}
                                    multiple={true}
                                    divClass={"w-full"}
                                    register={registers}
                                    label={"Option value"}
                                    placeholder={"Option value"}
                                    error={errors?.formData?.[index]?.checkBoxOption?.[idx]?.message}
                                />
                                <button type="button" onClick={() => handleRemoveOption(index, idx)} className="ml-2">
                                    <svg className="h-8 w-8 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        ))}


                        <button type="button" onClick={() => handleAddOption(index, "checkBoxOption")} className="flex items-center mt-2">
                            <svg className="h-8 w-8 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            Add other option or Add more
                        </button>
                    </div>
                )}

                {(formData.type === 'dropdown') && (
                    <div className="flex flex-col mt-4">
                        {dropdownOption?.map((items, idx) => (
                            <div key={idx} className="flex bg-transparent items-center mb-2 sm:flex-row flex-col">
                                <span className="sm:mr-2">{idx + 1}.</span>
                                <div className='flex justify-between w-full sm:flex-row flex-col'>
                                    <Input
                                        type="text"
                                        id={`dropdownOption-${index}-${idx}`}
                                        name={`formData.${index}.dropdownOption.${idx}.value`}
                                        className="!bg-transparent w-full"
                                        divClass={"sm:!w-[46%] w-full"}
                                        error={errors?.formData?.[index]?.dropdownOption?.[idx]?.value?.message}
                                        defaultValue={items.value}
                                        register={registers}
                                        label={"Dropdown value"}
                                        placeholder={"Add Value"}
                                    />
                                    <Input
                                        type="text"
                                        id={`dropdownOption-label-${index}-${idx}`}
                                        name={`formData.${index}.dropdownOption.${idx}.label`}
                                        className="!bg-transparent w-full"
                                        divClass={"mr-3 sm:!w-[50%] w-full"}
                                        error={errors?.formData?.[index]?.dropdownOption?.[idx]?.label?.message}
                                        defaultValue={items.value}
                                        register={registers}
                                        label={"Dropdown label"}
                                        placeholder={"Add label"}
                                    />
                                </div>

                                <button type="button" onClick={() => handleRemoveDropDown(index, idx)} className="ml-2">
                                    <svg className="h-8 w-8 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </button>

                            </div>
                        ))}


                        <button type="button" onClick={() => handleAddDDOption(index)} className="flex items-center mt-2">
                            <svg className="h-8 w-8 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#000000" stroke-width="1.5" stroke-linecap="round" />
                            </svg>
                            Add other option or Add more
                        </button>
                    </div>
                )}

                {formData.type === 'date' && (
                    <div className="flex flex-col mt-4 items-center w-full pointer-events-none">
                        <Space direction="vertical" size={12}>
                            <DatePicker format={"MM/DD/YYYY"} placeholder='MM/DD/YYYY' name={`formData.${index}.date`} {...register(`formData.${index}.date`)} />
                        </Space>
                    </div>
                )}

{formData.type === 'button' && (
                    <div className="flex flex-col mt-4 items-center w-full pointer-events-none">
                        <Space direction="vertical" size={12}>
                            <button placeholder='Next' disabled name={`formData.${index}.button`} {...register(`formData.${index}.button`)}
                            className="flex items-center bg-pink-600 text-white px-2 py-2 rounded-lg shadow-md hover:bg-pink-400 max-h-11 mt-3"
                            >Next</button>
                            {/* <DatePicker format={"MM/DD/YYYY"} placeholder="MM/DD/YYYY" name={`formData.${index}.date`} {...register(`formData.${index}.date`)} /> */}
                        </Space>
                    </div>
                )}

                {formData.type === 'sign pad' && (
                    <div className='pointer-events-none flex justify-center w-full'>
                        <Signature showButton={false} />
                    </div>
                )}

                <div className="flex justify-end items-center p-4 flex-col sm:flex-row ">
                    <button type="button" onClick={() => handleRemoveField(index)} className="flex items-center me-2 mb-2 sm:mb-0">
                        <svg className="h-6 w-6 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M14 11V17" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                        {/* {formData?.type !== 'button' &&
                        
                        <>
                    <span>
                        <Switch
                            label="required"
                            onClick={(e) => { handleInputChange(index, 'required', e?.target?.checked) }}
                            name={`formData.${index}.required`}
                            {...register(`formData.${index}.required`)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={required}

                        />
                    </span>
                    <label>Required</label>
                        </>} */}

{formData?.type !== 'button' &&  formData?.type !== 'h1' && formData?.type !== 'h2' && formData?.type !== 'h3' && formData?.type !== 'h4' && formData?.type !== 'h5' && formData?.type !== 'normal text' &&
                        
                        <>
                    <span>
                        <Switch 
                            label="required"
                            onClick={(e) => { handleInputChange(index, 'required', e?.target?.checked) }}
                            name={`formData.${index}.required`}
                            {...register(`formData.${index}.required`)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            checked={required}

                        />
                    </span>
                    <label>Required</label>
                        </>}
                        
                </div>

            </Card>

            <div className='absolute -right-2 -bottom-2 flex space-x-4'>
               
            {/* <button
                    type="button"
                    onClick={handleAddNextButton}
                    className="flex items-center bg-pink-600 text-white px-2 py-2 rounded-lg shadow-md hover:bg-pink-400 max-h-11 mt-3"
                >
                   Next Button
                </button> */}


                <button
                    type="button"
                    onClick={handleAddSectionClick}
                    className="flex items-center bg-pink-600 text-white pl-2 py-2 rounded-lg shadow-md hover:bg-pink-400 max-h-11 mt-3"
                >
                    <svg className="mr-2 h-6 w-6 text-white-500 inline" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                </button>
            </div>
        </div>



    );
};


export default FormSection;