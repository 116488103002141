import React from 'react'
import {Outlet,Navigate, useParams} from 'react-router-dom'

const EmailPrivateRoute = () => {

  // const {storeId} = useParams()

  // const details = localStorage.getItem('items')
  // const isAdmin = details ? JSON.parse(details) : null

  // const isUser = localStorage.getItem('user')
  const storeId = localStorage.getItem("store_uuid")
  return storeId ? <Outlet/>: <Navigate to="/:storeId/:form_category"/>
}

export default EmailPrivateRoute