import React from 'react'

const Input = ({ type, name, onchange, id, value, className, keyUp, keyDown, isDisabled, error, placeholder, register, label, divClass, defaultValue, multiple=false }) => {

  return (
    <div className={`flex flex-col ${divClass}`}>
      <input
        type={type}
        name={name}
        multiple={multiple}
        onChange={(e) => {
          if (onchange) onchange(e);
        }}
        value={value}
        defaultValue={defaultValue}
        id={id}
        className={`p-3 border-b-2 outline-0 ${className} ${error ? 'border-red-500' : 'border-black'}`}
        onKeyUp={(e) => {
          if (keyUp) keyUp(e);
        }}
        onKeyDown={(e) => {
          if (keyDown) keyDown(e);
        }}
        disabled={isDisabled}
        {...register(name, { required: { value: true, message: `${label} is required` } })}
        placeholder={placeholder}
      />
      {error && <span className="text-red-500 mt-1">{error}</span>}
    </div>

  )
}


export default Input;