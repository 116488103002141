import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { useCreate, useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { MdDeleteForever } from 'react-icons/md';
import CustomModal from '../../component/UI Component/Modal';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import { message } from 'antd';
// import { useFetch } from '../../hooks/Api';
import CommonTable from '../../component/Common table/Table';

const List_Store = () => {
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_API_STORES.toString();
    const deleteapiname = process.env.REACT_APP_API_STORES.toString();

    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const itemsPerPage = 10;
    const [state, setState] = useState({
        open: false
    });
    const [response, setResponse] = useState();
    const query = new URLSearchParams({
        search: inputValue !== "" ? inputValue : "",
        page: currentPage,
        limit: 10,
    }).toString();
    const { data, error, isFetching, refetch, isError, isSuccess } = useFetch(`${apiname}?${query}`);


    useEffect(() => {
        if (isFetching && error === null) {
            refetch();
        }
    }, [inputValue, currentPage]);


    // useEffect(() => {
    //     if (isSuccess) {
    //         console.log(data?.message, "ohh")
    //         setState({ open: true });
    //         setTimeout(() => {
    //             navigate("/layout/stores");
    //         }, 1000);
    //     } else if (isError) {
    //         console.log(error, "isError");
    //         setState({ open: true });
    //     }
    // }, [isSuccess, isError, error, data, navigate]);
    if (!data) return null;

    const totalPages = Math.ceil(data?.totalRows / itemsPerPage)

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleAddClick = () => {
        navigate('/layout/AddNewStore');
    };

    const headers = [
        { key: 'id', value: 'Sr No.' },
        { key: 'store_name', value: 'Store Name' },
        { key: 'address', value: 'Address' },
        { key: 'phone', value: 'Phone' },
        { key: 'state_name', value: 'State' },
        { key: 'city_name', value: 'City' },
        { key: 'zip_code', value: 'Zip Code' },
        { key: 'action', value: 'Action' }
    ];
    const startIndex = (currentPage - 1) * 10;

    const rows = data?.data?.map((e, index) => ({
        id: e.id,
        index: startIndex + index + 1,   
        store_name: e.store_name,
        address: e.address,
        phone: e.phone,
        state_name: e.state_name,
        city_name: e.city_name,
        zip_code: e.zip_code,
    }));

    const handleSearchChange = (query) => {
        setInputValue(query);
        setCurrentPage(1);
    };

    return (
        <>
            {(data === "There is no Store yet!" || error) ? (
                <p className='flex justify-center text-3xl'>{error ? error.message : "No Store"}</p>
            ) : (
                    <div>
                        <CommonTable
                            showAddButton={true}
                            showSearch={true}
                            ButtonLabel={"Add Store"}
                            headers={headers}
                            rows={rows || []}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            onEditClick={(id) => navigate(`/layout/EditNewStore/${id}`)}
                            apiname={apiname}
                            deleteapiname={deleteapiname}
                            refetch={refetch}
                            onAddClick={handleAddClick}
                            searchQuery={inputValue}
                            onSearchChange={handleSearchChange}
                        />
                    </div>
            )}
        </>
    );
};

export default List_Store;