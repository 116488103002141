// src/components/ActionButtons.jsx
import React from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';

const ActionButtons = ({ onEditClick, handleDeleteClick, rowId }) => {
  return (
    <div className="flex flex-inline justify-center gap-2">
      <button 
        onClick={() => onEditClick(rowId)} 
        aria-label="Edit" 
        // className="flex justify-start sm:mb-2 md:mb-0"
      >
        <FaRegEdit size={20} style={{ color: '#DB2777' }} />
      </button>
      <button 
        onClick={() => handleDeleteClick(rowId)} 
        // className="flex justify-start sm:mb-2 md:mb-0 mx-1"
        aria-label="Delete"
      >
        <MdDeleteForever size={23} style={{ color: '#DB2777' }} />
      </button>
    </div>
  );
};

export default ActionButtons;
