import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { FaRegEdit, FaWpforms } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import CustomModal from '../../component/UI Component/Modal';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import { FilterOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ActionButtons from '../ActionButton/ActionButton';
import { useColumnSearch } from "./useColumnSearch";
import { Tooltip } from 'antd';

const CommonTable = ({
    headers,
    rows,
    totalPages,
    onPageChange,
    onEditClick,
    apiname,
    deleteapiname,
    ButtonLabel,
    refetch,
    onAddClick,
    searchQuery,
    onSearchChange,
    showAddButton = true,
    showSearch = true,
    tableRows,
    currentPage
}) => {
    const {
        columnSearch,
        filteredRows,
        isLoading,
        error,
        handleColumnSearchChange,
        clearSearch,
    } = useColumnSearch({
        apiname,
        refetchData: refetch,
    });
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [response, setResponse] = useState(null);
    const [state, setState] = useState({ open: false });
    const [showColumnSearch, setShowColumnSearch] = useState(false);
    const navigate = useNavigate();

    const handleDeleteClick = async (id) => {
        setUserId(id);
        setOpen(true);
    };

    const handleYes = async () => {
        setOpen(false);
        deleteUser(userId);
    };

    const handleNo = () => {
        setOpen(false);
    };

    const deleteUser = async (id) => {
        try {
            const localData = JSON.parse(localStorage.getItem('items'));
            const res = await axios.delete(`${process.env.REACT_APP_BASE_URL}${deleteapiname}/${id}`, {
                headers: { authorization: `Bearer ${localData?.token}` },
            });
            setResponse(res);
            setState({ open: true });
            await refetch();
        } catch (error) {
            setResponse(error.response || { data: { message: 'Failed to delete' } });
            setState({ open: true });
        }
    };


    const toggleColumnSearchVisibility = () => {
        setShowColumnSearch(prev => !prev);
        clearSearch();
    };

    const getSnackbarStyle = () => {
        return response?.status === 204
            ? { backgroundColor: "red", color: "white" }
            : { backgroundColor: "green", color: "white" };
    };

    const handleCloseSnackbar = () => {
        setState({ open: false });
    };

    const rowsData = filteredRows?.results?.data?.map((e, index) => {
        if (e.required_draws_before_freeze == 1) {
            e.required_draws_before_freeze = 'True'
        } else if (e.required_draws_before_freeze == 0) {
            e.required_draws_before_freeze = 'False'
        }

        if (e.tan_tax_exempt == 1) {
            e.tan_tax_exempt = 'True'
        } else if (e.tan_tax_exempt == 0) {
            e.tan_tax_exempt = 'False'
        }

        const startIndex = (currentPage - 1) * 10;

        const row = { id: startIndex + index + 1, id: e.id };
        headers.forEach(header => {
            const key = header.key;
            if (key == "action" || key == "forms") {
                return;
            }
            else {
                row[key] = e[key] || '-';
            }
        });

        return row;
    });


    return (
        <>
            {showAddButton && (
                <div className="flex justify-end mb-2">
                    <button
                        className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700"
                        onClick={onAddClick}
                    >
                        {ButtonLabel}
                    </button>
                </div>
            )}

            {(window.location.pathname === "/layout/listUser" || window.location.pathname === "/layout/liststore") && (
                <div className="flex justify-end mb-2">
                    <button
                        onClick={toggleColumnSearchVisibility}  // Toggle visibility
                        className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700"
                    >
                        {showColumnSearch ? <CloseOutlined /> : <FilterOutlined />}
                    </button>
                </div>
            )}
            {showSearch && (
                <input
                    type="search"
                    value={searchQuery}
                    onChange={(e) => onSearchChange(e.target.value)}
                    className="flex w-96 border border-gray-300 rounded-lg p-2 ml-auto"
                    placeholder="Search"
                />
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white uppercase bg-pink-600 dark:bg-pink-200 dark:text-gray-400">
                        <tr>
                            {headers?.map((header, idx) => {
                                return (
                                    <th key={idx} scope="col" className="px-3 py-3 text-center">
                                        <div className="flex flex-col items-center">
                                            <span className="font-semibold">{header.value}</span>
                                            {showColumnSearch && header.value !== 'Action' && header.value !== "Forms" && header.value !== "Sr No." && (
                                                <div className="relative w-32 mt-2">
                                                    <input
                                                        type="text"
                                                        value={columnSearch[header.key] || ''}
                                                        onChange={(e) => handleColumnSearchChange(header.key, e.target.value)}
                                                        className="w-full px-2 py-1 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:border-transparent placeholder-gray-400"
                                                        placeholder={`Search ${header.value}`}
                                                        style={{ color: 'black' }}
                                                    />

                                                    {columnSearch[header.key] && (
                                                        <button
                                                            type="button"
                                                            onClick={() => handleColumnSearchChange(header.key, '')}
                                                            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 text-xl"  // Increase size with 'text-xl'
                                                        >
                                                            &times;
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        
                        {isLoading ? (
                           <tr>
                           <td colSpan={headers.length} style={{ textAlign: 'center', padding: '20px' }}>
                             <div style={{
                               border: '4px solid rgba(255, 255, 255, 0.3)',
                               borderTop: '4px solid rgb(219 39 119 / var(--tw-bg-opacity))',
                               borderRadius: '50%',
                               width: '40px',
                               height: '40px',
                               margin: '0 auto 10px',
                               animation: 'spin 1s linear infinite',
                             }}></div>
                             Loading...
                           </td>
                         </tr>
                         
                        ) : error ? (
                            <tr>
                                <td colSpan={headers.length}>Error: {error}</td>
                            </tr>
                        ) :
                            (filteredRows?.results?.data?.length !== 0 || filteredRows.length !== 0) ? (

                                rowsData?.map((row, index) => (
                                    <tr
                                        key={row?.id}
                                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-pink-50 even:dark:bg-pink-200 border-b dark:border-pink-700"
                                    >
                                        <td>{index + 1}</td>



                                        {Object.values(row).map(
                                            (value, idx) =>
                                                idx !== 0 && (
                                                    <>
                                                        <td key={idx} className="px-6 py-4">
                                                        {value?.length >= 25 ?
                                                            <Tooltip title={<p style={{ fontSize: '15px', color: 'white' }}>{value}</p>} placement="top" arrow >
                                                                <div>
                                                                    {value?.length >= 25 ? value?.slice(0, 25)?.concat('...') : value}
                                                                </div>
                                                            </Tooltip>
                                                        :value}
                                                        </td>
                                                    </>
                                                )
                                        )}
                                        <td className="px-6 py-4">
                                            <ActionButtons
                                                onEditClick={onEditClick}
                                                handleDeleteClick={handleDeleteClick}
                                                rowId={row.id}
                                            />
                                        </td>
                                        {window.location.pathname === "/layout/listUser" && (
                                            <td className="px-6 py-4">
                                                <button
                                                    onClick={() => {
                                                        navigate("/layout/userforms");
                                                        localStorage.setItem("userID", row.id);
                                                        localStorage.setItem("storeId", row.store_uuid);
                                                    }}
                                                >
                                                    <FaWpforms size={20} style={{ color: "#DB2777" }} />
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                rows.map((row, index) => (
                                    <tr
                                        key={row.id}
                                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-pink-50 even:dark:bg-pink-200 border-b dark:border-pink-700"
                                    >
                                        {Object.values(row).map(
                                            (value, idx) =>
                                                idx !== 0 && (
                                                    <td key={idx} className="px-6 py-4">
                                                        {value}
                                                    </td>
                                                )
                                        )}
                                        <td className="px-6 py-4">
                                            <ActionButtons
                                                onEditClick={onEditClick}
                                                handleDeleteClick={handleDeleteClick}
                                                rowId={row.id}
                                            />
                                        </td>
                                        {window.location.pathname === "/layout/listUser" && (
                                            <td className="px-6 py-4">
                                                <button
                                                    onClick={() => {
                                                        navigate("/layout/userforms");
                                                        localStorage.setItem("userID", row.id);
                                                        localStorage.setItem("storeId", row.store_uuid);
                                                    }}
                                                >
                                                    <FaWpforms size={20} style={{ color: "#DB2777" }} />
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))
                            )}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-4">
                <Stack spacing={2}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={onPageChange}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                            '& .MuiPaginationItem-root': {
                                color: '#DB2777',
                                borderColor: '#DB2777',
                                backgroundColor: 'transparent',
                            },
                            '& .Mui-selected': {
                                backgroundColor: '#DB2777',
                                color: '#ffffff',
                                borderColor: '#DB2777',
                            },
                            '& .MuiPaginationItem-ellipsis': {
                                color: '#DB2777',
                            },
                        }}
                    />
                </Stack>
            </div>
            <CustomModal
                open={open}
                onClose={handleNo}
                onYes={handleYes}
                onNo={handleNo}
                text="Are you sure you want to delete?"
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.open}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                ContentProps={{
                    style: getSnackbarStyle(),
                }}
                message={response?.data?.message || 'An error occurred'}
            />
        </>
    );
};

export default CommonTable;
