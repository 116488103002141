import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import { Snackbar } from '@mui/material';
import { useFetch } from '../../hooks/Api';
import { Select } from 'antd';

const Edit_Register_Store = () => {
  const { id } = useParams();


  const getApi = process.env.REACT_APP_API_USER_INFO?.toString()
  const apiname = process.env.REACT_APP_API_SM_UPDATE_DELETE?.toString();
  const localStorageData = localStorage.getItem('items')
  const tokenData = localStorageData ? JSON.parse(localStorageData) : null
  const { error, mutate, isError, isLoading, data, isSuccess } = useMutation(async (data) => {
    const updateStoreUserDetails = await axios.put(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, data, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      },
    })
    return updateStoreUserDetails.data
  })
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState([])
  const [state, setState] = React.useState({
    open: false
  });

  const [storeValue,setStoreValue] = useState()
  const apinameStore = process.env.REACT_APP_API_STORES?.toString();
  const { data:storeData, error:storeError, isFetching:storeIsFetching, refetch:storeRefetch } = useFetch(apinameStore,1,100000);


  const [storeOptions,setStoreOptions] = useState([])
  useEffect(()=>{
    if(storeData){
      let data = storeData?.data?.map((e)=>{return {label:e.store_name, value:e.id}})
      setStoreOptions(data)
    }
  },[storeData])

  const onChangestore = (value) => {
    setValue('store_id',value)
    setStoreValue(value)
    clearErrors('store_id')

  };
  
  const onSearchstore = (value) => {
    console.log('search:', value);
  };


  const { register, handleSubmit, setError, formState: { errors }, reset, setValue, clearErrors } = useForm({
    defaultValues: {
      store_manager_name: userDetails?.store_manager_name || "",
      email: userDetails?.email || "",
      store_address: userDetails?.store_address || "",
    }
  });

  useEffect(() => {
    if (userDetails) {
      reset()
      setValue('store_manager_name', userDetails.store_manager_name)
      // setValue('name', userDetails.name)
      setValue('email', userDetails.email)
      setValue('city', userDetails.city)
      setValue('state', userDetails.state)
      setValue('country', userDetails.country)
      setValue('store_address', userDetails.store_address)


      const matchingStore = storeOptions?.find((e) => e.value === userDetails?.store_id);
      setValue('store_id', matchingStore?.value)
      setStoreValue(matchingStore)
    }
  }, [userDetails, storeOptions])

  const onSubmit = (data) => {
    let finalData = {
      ...data,
      role: 'store'
    }
    mutate(finalData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${getApi}/${id}`, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      }
    });
    setUserDetails(res.data.results);
  }
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };

  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      
      setTimeout(() => {
        navigate("/layout/liststore");
      }, 1000);
    } else if (isError) {
      setState({ open: true });
    }
  }, [data, error, isSuccess,navigate]);


 


  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Store Register</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="">
                  Store
                </label>
                <Select
                                    {...register('store_id', { required: { value: true, message: 'Store Is Required' } })}

                className='w-full'
                style={{height:'50px'}}
    showSearch
    value={storeValue}
    placeholder="Select a Store"
    optionFilterProp="label"
    onChange={onChangestore}
    onSearch={onSearchstore}
    options={storeOptions}
    />
                <p style={{ color: "Red" }}>{errors?.store_id?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="StoreManagerName">
                  Store Manager Name
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <input
                    {...register('store_manager_name',
                      {
                        required: { value: true, message: 'Store Manager Name Is Required' },
                        pattern: { value: /^[A-Za-z\s]+$/, message: 'Only alphabetic characters and spaces are allowed' }
                      },)}
                    name='store_manager_name'
                    type="text"
                    // defaultValue={userDetails.store_manager_name}
                    id="StoreManagerName"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Store Manager Name"
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.store_manager_name?.message}</p>
              </div>


              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                  Email
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <svg class="h-8 w-8 text-gray-400" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="3 9 12 15 21 9 12 3 3 9" />  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />  <line x1="3" y1="19" x2="9" y2="13" />  <line x1="15" y1="13" x2="21" y2="19" /></svg>
                  <input
                    name='email'
                    {...register('email', {
                      required: { value: true, message: 'Email Is Required' },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                        message: "Please enter a valid email",
                      },
                    })}
                    type="email"
                    id="email"
                    // defaultValue={userDetails.email}
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Email"
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.email?.message}</p>
              </div>

            



            </div>
            <div className='mt-6 text-center flex justify-center'>
              <button className="flex flex-row justify-center items-center bg-pink-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-pink-400" type='submit'
                disabled={isLoading}
              >
                Update
                {isLoading && <div className="mx-2 w-4 h-4 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>}
              </button>
            </div>

          </form>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        TransitionComponent={state.Transition}
        message={error?.response?.data?.message || data?.message}
        key={state?.Transition?.name}
        autoHideDuration={3000}
      />
    </>
  );
}

export default Edit_Register_Store;
