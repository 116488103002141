import React, { useEffect, useState } from 'react';
import { useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { FaRegEdit, FaWpforms } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CustomModal from '../../component/UI Component/Modal';
import { Snackbar } from '@mui/material';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
import CommonTable from '../../component/Common table/Table';
const List_User = () => {
    // alert('here')
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const [currentSearch, setCurrentSearch] = useState("search");
    const itemsPerPage = 10;
    const localStorageData = localStorage.getItem('items');
    const tokenData = localStorageData ? JSON.parse(localStorageData) : null;
    const apiname = process.env.REACT_APP_API_USERS.toString();
    const deleteapiname = process.env.REACT_APP_API_USER_UPDATE_DELETE.toString();
    const apiData = tokenData?.roles === 'store' ? `${apiname}/${tokenData?.store_uuid}` : `${apiname}`;


    const buildQueryParams = (inputValue, currentSearch, currentPage) => {
        const queryParams = {
            page: currentPage,
            limit: 10,
        };

        if (inputValue !== "") {
            switch (currentSearch) {
                case 'search':
                    queryParams.search = inputValue;
                    break;
                case 'firstName':
                    queryParams.firstName = inputValue;
                    break;
                case 'lastName':
                    queryParams.lastName = inputValue;
                    break;
                case 'email':
                    queryParams.email = inputValue;
                    break;
                case 'dob':
                    queryParams.dob = inputValue;
                    break;
                default:
                    break;
            }
        }

        return new URLSearchParams(queryParams).toString();
    };

    const query = buildQueryParams(inputValue, currentSearch, currentPage);
    console.log(query,"query",apiData)
    const { data, error, isFetching, refetch } = useFetch(`${apiData}?${query}`);

    useEffect(() => {
        if (isFetching && error === null || inputValue === "") {
            refetch();
        }
    }, [inputValue, currentPage, inputValue]);

    if (!data) return null;

    const totalPages = Math.ceil(data.totalRows / itemsPerPage);

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const headers = [
        {key:'id',value:'Sr No.'},
        {key:'first_name',value:'First Name'},
        {key:'last_name',value:'Last Name'},
        {key:'mobile',value:'Mobile Number'},
        {key:'dob',value:'Date Of Birth'},
        {key:'email',value:'Email'},
        {key:'store_uuid',value:'Store Id'},
        {key:'action',value:'Action'},
        {key:'forms',value:'Forms'},
    ];
    const startIndex = (currentPage - 1) * 10;

    console.log(data?.data)

    const rows = data?.data?.map((e, index) => {
        const personal_details = e?.personal_details ? JSON.parse(e?.personal_details) : null;

        return {
            id: e.id,
            index: startIndex + index + 1,
            first_name: e?.first_name ? e?.first_name : '-',
            last_name: e?.last_name ? e?.last_name : '-',
            mobile: e?.mobile,
            dob: e?.dob ? e?.dob : '-',
            // store_name: e?.store_name,
            email: e?.email,
            store_uuid: e?.store_uuid,
        };
    });


    return (
        <>
            {(data === 'No user found !' || data === 'Store Id not found' || data === "There is no user yet!" || error) ? (
                <>
                    <p className='flex justify-center text-3xl'>{error ? error?.message : "No user Found"}</p>
                </>
            ) : (
                <>
                    <div>
                        <CommonTable
                            showAddButton={false}
                            showSearch={false}
                            headers={headers}
                            rows={rows || []}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            onEditClick={(id) => navigate(`/layout/EditUser/${id}`)}
                            apiname={apiname}
                            deleteapiname={deleteapiname}
                            refetch={refetch}
                            searchQuery={inputValue}
                        // onSearchChange={handleSearchChange}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default List_User;
