import React,{useEffect} from 'react'
import Header from '../../layout/subComponents/Header'
import Latan from '../../images/copy1.png';
import Latannewlogo from '../../images/sore_1.png';
import gif from '../../images/G7Ff1sDRZx.gif';

const AlreadySubmitted = () => {




  return (
    <>
    {/* <Header/> */}
    <div className="text-xl font-bold flex items-center justify-between w-full p-2 ">
        <img src={Latannewlogo} width={70} height={70}/>
        <img src={Latan} width={120} height={120}/>
        </div>
                      
    <div className='w-full h-96 justify-center flex my-10 flex-col items-center'>


        {/* <img src={gif} className='h-48 w-48'/> */}

      <div className='text-2xl text-gray-500 text-center'>You already submitted this form.</div>
    </div>
    </>
  )
}

export default AlreadySubmitted