// import React, { useState } from 'react';
// import Button from '@mui/material/Button';
// import { IoMdCloudUpload } from "react-icons/io";
// import { styled } from '@mui/material/styles';
// import { useForm } from 'react-hook-form';

// const VisuallyHiddenInput = styled('input')({
//     clip: 'rect(0 0 0 0)',
//     clipPath: 'inset(50%)',
//     height: 1,
//     overflow: 'hidden',
//     position: 'absolute',
//     bottom: 0,
//     left: 0,
//     whiteSpace: 'nowrap',
//     width: 1,
// });

// const UploadFileFormat = () => {
//     const [file, setFile] = useState(null);
//     // const [fileTmp, setFileTmp] = useState(null);
//     const { register, handleSubmit, formState: { errors } } = useForm();

//     const handleFileChange = (e) => {

//         setFile(e.target.files[0].name);
//     };
//     console.log(file,"file")

//     const onSubmit = (fileUpload) => {
//         const formData = new FormData();
//         for (const [key, value] of Object.entries(fileUpload)) {
//         //   const formvalue = value;

//         if (key === "image") {
//             for (let i = 0; i < file.length; i++) {

//               formData.append('image', file)
//             }
//             continue
//           }

//         }
//         console.log(formData)
//     };

//     return (
//         <div className='p-4 space-y-4'>
//             <div className="bg-white shadow rounded-lg p-4 w-10/12 sm:w-full">
//                 <div className="max-h-fit bg-white-100 flex items-center justify-center text-black-500">
//                     <div className='w-full'>
//                         <div className="p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-white-300 dark:border-gray-300 mt-3">
//                             <div className='flex flex-col'>

//                                 <Button
//                                     {...register('image')}
//                                     component="label"
//                                     role={undefined}
//                                     variant="contained"
//                                     // tabIndex={-1}
//                                     onChange={handleFileChange}
//                                     value={file}
//                                     startIcon={<IoMdCloudUpload />}
//                                 >
//                                     Upload file
//                                     <VisuallyHiddenInput type="file" accept="image/png, image/jpeg"/>
//                                 </Button>
//                                 <p className='mt-2 mb-2'>{file}</p>
//                                 <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700 mt-2" onClick={handleSubmit(onSubmit)} >
//                                     Submit
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default UploadFileFormat;

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { IoMdCloudUpload } from "react-icons/io";
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { useCreate } from '../../hooks/Api';
import { Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadFileFormat = () => {
  const apiname = process.env.REACT_APP_API_FORM_FILE_FORMAT?.toString()
  const { mutate, data, isError, isSuccess, error } = useCreate(apiname);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate()

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null); // For image preview

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // if (selectedFile) {
    setFile(selectedFile);

    // Create a file preview if the file is an image
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setFilePreview(reader.result);
    //     };
    //     reader.readAsDataURL(selectedFile);
    // }
  };
  const onSubmit = async (data) => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('docx_file', file);

    mutate(formData)
  };

  const [state, setState] = useState({
    open: false
  });


  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/listFiles");
      }, 1000);
    } else if (isError) {
      console.log(error, "isError");
      setState({ open: true });
    }
  }, [isSuccess, isError, error, data]);

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      console.log(error?.message, "error message")
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };


  return (
    <div className='p-4 space-y-4'>
      <div className="bg-white shadow rounded-lg p-4 w-10/12 sm:w-full">
        <p className='mb-4 font-bold text-2xl'>File Upload</p>
        <div className="max-h-fit bg-white-100 flex items-center justify-center text-black-500">
          <div className='w-full'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col'>
                <Button
                  style={{ width: '15%' }}
                  component="label"
                  role={undefined}
                  variant="contained"
                  startIcon={<IoMdCloudUpload />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept=".doc,.docx"
                    {...register('docx_file', { required: { value: true, message: 'File is Required' }, onChange: handleFileChange })}
                  />
                </Button>
                <span className='text-red-600'>{errors?.docx_file?.message} 
                {console.log(errors?.docx_file?.message,"error now messages")}
                </span>
                <p className='mt-2 mb-2'>{file ? file.name : 'No file selected'}</p>
                {filePreview && (
                  <img
                    src={filePreview}
                    alt="File preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    className='mt-2 mb-2'
                  />
                )}
                <button
                  type="submit"
                  className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700 mt-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        // onExited={() => {
        //   if (isSuccess) {
        //     navigate("/layout/liststore");
        //   }
        // }}
        message={error?.response?.data?.message || data?.message || error?.message}
      />
    </div>
  );
};

export default UploadFileFormat;



