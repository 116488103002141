import React, { useState } from 'react'
import { useFetch } from '../../hooks/Api';
import { useNavigate } from 'react-router-dom';
import prev_image from '../../images/image_prev1.png'
import { Pagination, Snackbar, Stack } from '@mui/material';
import axios from 'axios';
import Loader from '../../component/UI Component/Loader';
import { FaFileDownload } from 'react-icons/fa';
import { MdDeleteOutline } from "react-icons/md";

const List_User_Form = () => {
    const UserId = localStorage.getItem("userID")
    const localData = JSON.parse(localStorage.getItem("items"));
    const apiname = process.env.REACT_APP_API_FORMS.toString();
    const getformAPI = process.env.REACT_APP_API_FORM_DOWNLOAD?.toString();
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const query = new URLSearchParams({
        search: inputValue !== "" ? inputValue : "",
        page: currentPage,
        limit: 10,
        storeId:localStorage.getItem('storeId')
    }).toString();
    const { data, error, refetch } = useFetch(`${apiname}/${UserId}?${query}`)

    const handleDownload = async (formData) => {
        try {
            setLoading(true)
            const localData = JSON.parse(localStorage.getItem("items"));
            const PdfRes = await axios.get(`${process.env.REACT_APP_BASE_URL}${getformAPI}/${UserId}/${formData.id}`, {
                headers: { 'authorization': `Bearer ${localData?.token}` },
                responseType: 'blob'
            })
            const url = URL.createObjectURL(PdfRes.data);
            window.open(url, '_blank');

            const link = document.createElement('a');
            link.href = url;
            link.download = `${formData.form_name}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false)
        } catch (error) {
            console.log("🚀 ~ handleDownload ~ error:", error)
            setLoading(false)
        }

    }


    const deleteformAPI = process.env.REACT_APP_API_DELETE_FORM?.toString();

    const handleDelete = async (formData) => {
        try {
            setLoading(true)
            const localData = JSON.parse(localStorage.getItem("items"));
            let data = {
                form_id:formData.id,
                user_id:UserId,
                store_id:localStorage.getItem('storeId')
            }
            const PdfRes = await axios.delete(`${process.env.REACT_APP_BASE_URL}${deleteformAPI}`, {data,
                headers: { 'authorization': `Bearer ${localData?.token}` },
            })
        //    return PdfRes
            setLoading(false)
            refetch()
        } catch (error) {
            console.log("🚀 ~ handleDownload ~ error:", error)
            setLoading(false)
        }

    }

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const itemsPerPage = 10;
    const totalPages = Math.ceil(data?.totalRows / itemsPerPage);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setCurrentPage(1);
    };

    return (
        <>
            <div className="bg-white shadow rounded-lg p-3 w-full">
                <div className="text-gray-600 mb-2 text-lg font-semibold">Forms</div>

                <div className="flex justify-between items-center">
                    <input
                        type='search'
                        value={inputValue}
                        onChange={handleInputChange}
                        className='w-96 border border-gray-300 rounded-lg p-2 ml-auto'
                        placeholder={`Search form`}
                    />
                </div>

                {
                    (data === 'No forms found!' || error) ? (
                        <>
                            <p className='flex justify-center text-3xl'>{error ? error.message : "No forms found."}</p>
                        </>
                    ) : (
                        <div className={`p-2 space-y-4 ${loading === true ? "pointer-events-none" : ""}`}>


                            <div className='grid xs:grid-cols-1 sm:grid-cols-2 medium:grid-cols-3 lg:grid-cols-4 lg:gap-x-6 medium:gap-6 sm:gap-6 gap-4'>
                                {data?.data?.map((e) => (
                                    <div
                                        key={e.id}
                                        className='relative border-8 border-pink-200 hover:border-pink-500 h-80 rounded-md shadow-lg overflow-hidden cursor-pointer transition-all duration-300'
                                    >
                                        {/* Blurred Background Image */}
                                        <div
                                            className='absolute inset-0 bg-cover bg-center blur-sm'
                                            style={{ backgroundImage: `url(${prev_image})` }}
                                        ></div>

                                        {/* Content Overlay */}
                                        <div className='absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-between p-4'>
                                            <p className='text-orange-300 text-2xl font-bold h-24 w-full flex items-center justify-center text-center'>
                                                {e?.form_name}
                                            </p>
                                            <p className='text-white h-12 w-full flex items-center justify-center text-center'>
                                                {e?.form_description}
                                            </p>

                                          
                                            <div className='flex justify-between '>

                                                {localData?.roles == 'admin' &&
                                                <div>
                                            <button
                                            onClick={() => handleDelete(e)}
                                            className='mx-1 ml-auto'
                                        >
                                            <MdDeleteOutline size={23} style={{ color:  "rgb(255 243 248)" }}/>
                                        </button>
                                        </div>
}

                                        <div>

                                        
                                        <button
                                            onClick={() => handleDownload(e)}
                                            className='mx-1 ml-auto'
                                        >
                                            <FaFileDownload size={23} style={{ color:  "rgb(255 243 248)" }} />
                                        </button>
                                        </div>
                                            </div>
                                            
                                        </div>
                                      
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-end mt-4">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        // color="primary"
                                        variant="outlined"
                                        shape="rounded"
                                        sx={{
                                            '& .MuiPaginationItem-root': {
                                                color: '#DB2777',
                                                borderColor: '#DB2777',
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#DB2777',
                                                color: '#ffffff',
                                            },
                                            '& .MuiPaginationItem-ellipsis': {
                                                // color: '#DB2777',
                                            },
                                        }}
                                    />
                                </Stack>
                            </div>
                        </div>
                    )
                }

            </div>
            {
                loading ? (
                    <Loader />
                ) : null
            }
        </>

    )
}
export default List_User_Form;