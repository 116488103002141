import React from 'react';
import Latannewlogo from '../../images/sorelogo.png'
import Latan from '../../images/copy1.png'
import { Link } from 'react-router-dom';

const Header = ({ toggleMobileMenu }) => {
  return (
    <header className="bg-cyan-950 text-white p-4 flex justify-between items-center shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
      <div className="text-xl font-bold flex  items-center justify-between lg:w-full md:w-full w-full xs:w-48">
        <img src={Latannewlogo} className='lg:h-14 md:h-14 h-10'/>
        <img src={Latan} className='lg:h-12 md:h-12 h-10 ml-4'/>
        </div>
      {/* <nav className="hidden md:flex space-x-4">
        <Link to="/layout" className="hover:underline">Home</Link>
        <Link to="#" className="hover:underline">About</Link>
        <Link to="#" className="hover:underline">Services</Link>
        <Link to="#" className="hover:underline">Contact</Link>
      </nav> */}
      <button
        className="md:hidden p-2 focus:outline-none"
        onClick={toggleMobileMenu}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      </button>
    </header>
  );
};

export default Header;
