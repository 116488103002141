import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreate } from '../../hooks/Api'
import Snackbar from '@mui/material/Snackbar';
import { message } from 'antd';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
import { useFetch } from '../../hooks/Api';

const Add_Membership = () => {

  const apiname = process.env.REACT_APP_API_MEMBERSHIPS?.toString();

  const { mutate, data, isError, isSuccess, error, isLoading } = useCreate(apiname);
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();
  const navigate = useNavigate()

  const [FreezeValue, setFreezeValue] = useState()
  const FreezeOptions = [
    {
      value: 1,
      label: "True"
    },
    {
      value: 0,
      label: "False"
    }
  ]

  const onChangeFreeze = (value) => {
    setValue('required_draws_before_freeze', value)
    clearErrors('required_draws_before_freeze')

  };
  const onChangeTaxExempt = (value) => {
    setValue('tan_tax_exempt', value)
    clearErrors('tan_tax_exempt')

  };

  const onSearchFreeze = (value) => {
    console.log('search:', value);
  };

  const onSubmit = (data) => {
    let finalData = {
      ...data,
    }
    mutate(finalData)
  }


  const [state, setState] = useState({
    open: false
  });



  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (error?.response?.data?.message || error?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (data?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };


  useEffect(() => {
    if (isSuccess) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/memberships");
      }, 1000);
    } else if (isError) {
      console.log(error, "isError");
      setState({ open: true });
    }
  }, [isSuccess, isError, error, data, navigate]);
  const handleNumbercode = (e) => {
    let temp = e.target.value.replace(/\D/g, '');
    if (temp.length > 15) temp = temp.substring(0, 15);
    e.target.value = temp;
}
  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Add Membership</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Membership Number
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <input
                    type="number"
                    id="membership_number"
                    name="membership_number"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Membership Number"
                    {...register('membership_number', {
                      required: 'Membership Number is required',
                    })}
                    onChange={handleNumbercode}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.membership_number?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Membership Name
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <input
                    type="text"
                    id="membership_name"
                    name="membership_name"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Membership Name"
                    {...register('membership_name', {
                      required: 'Membership Name is required',
                    })}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.membership_name?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Monthly Dues
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <input
                    type="number"
                    id="monthly_dues"
                    name="monthly_dues"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Monthly Dues"
                    {...register('monthly_dues', {
                      required: 'Monthly Dues is required',
                    })}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.monthly_dues?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Required Draws
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <input
                    type="number"
                    id="required_draws"
                    name="required_draws"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Required Draws"
                    {...register('required_draws', {
                      required: 'Required Draws is required',
                    })}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.required_draws?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Monthly Freeze Dues
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <input
                    type="number"
                    id="monthly_freeze_dues"
                    name="monthly_freeze_dues"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Monthly Freeze Dues"
                    {...register('monthly_freeze_dues', {
                      required: 'Monthly Freeze Dues is required',
                    })}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.monthly_freeze_dues?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Required Draws Before Freeze
                </label>
                  <Select
                    {...register('required_draws_before_freeze', { required: { value: true, message: 'Required Draws Before Freeze Is Required' } })}
                    className='w-full'
                    style={{ height: '50px' }}
                    showSearch
                    value={FreezeValue}
                    placeholder="Select a Required Draws Before Freeze"
                    optionFilterProp="label"
                    onChange={onChangeFreeze}
                    onSearch={onSearchFreeze}
                    options={FreezeOptions}
                  />
                <p style={{ color: "Red" }}>{errors?.required_draws_before_freeze?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Tan Tax Exempt
                </label>
                <Select
                    {...register('tan_tax_exempt', { required: { value: true, message: 'Tan Tax Exempt Is Required' } })}
                    className='w-full'
                    style={{ height: '50px' }}
                    showSearch
                    value={FreezeValue}
                    placeholder="Select a Tan Tax Exempt"
                    optionFilterProp="label"
                    onChange={onChangeTaxExempt}
                    onSearch={onSearchFreeze}
                    options={FreezeOptions}
                  />
                <p style={{ color: "Red" }}>{errors?.tan_tax_exempt?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Current Sunlync Document Name
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <input
                    type="text"
                    id="current_sunlync_document_name"
                    name="current_sunlync_document_name"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Current Sunlync Document Name"
                    {...register('current_sunlync_document_name', {
                      required: 'Current Sunlync Document Name is required',
                    })}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.current_sunlync_document_name?.message}</p>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="membership">
                  Membership Details
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  <textarea
                    type="text"
                    id="membership_details"
                    name="membership_details"
                    className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                    placeholder="Enter your Membership Details"
                    {...register('membership_details', {
                      required: 'Membership Details is required',
                    })}
                  />
                </div>
                <p style={{ color: "Red" }}>{errors?.membership_details?.message}</p>
              </div>


            </div>
            <div className='text-center'>
              <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>


      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        onExited={() => {
          if (isSuccess) {
            navigate("/layout/memberships");
          }
        }}
        message={error?.response?.data?.message || data?.message || error?.message}
      />
      {
        isLoading ? (
          <Loader />
        ) : null
      }

    </>
  );
}

export default Add_Membership;
