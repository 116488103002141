import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit } from 'react-icons/fa';
import { useFetch } from '../../hooks/Api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { MdDeleteForever } from 'react-icons/md';
import CustomModal from '../../component/UI Component/Modal';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import CommonTable from '../../component/Common table/Table';

const List_State = () => {
    const navigate = useNavigate();
    const apiname = process.env.REACT_APP_API_STATES.toString();
    const deleteapiname = process.env.REACT_APP_API_STATES.toString();
    const [response, setResponse] = useState()
    const [state, setState] = useState({
        open: false
    });
    const [open, setOpen] = useState(false);
    const [userId, setUserId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState("");
    const itemsPerPage = 10;

    const query = new URLSearchParams({
        search: inputValue !== "" ? inputValue : "",
        page: currentPage,
        limit: 10,
    }).toString();
    const { data, error, isFetching, refetch } = useFetch(`${apiname}?${query}`);

    useEffect(() => {
        if (isFetching && error === null) {
            refetch();
        }
    }, [inputValue, currentPage]);

    if (!data) return null;

    const totalPages = Math.ceil(data?.totalRows / itemsPerPage);

    const handleAddClick = () => {
        navigate('/layout/AddState');
    };

    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const headers = [
        { key: 'id', value: 'Sr No.' },
        { key: 'state_name', value: 'State Name' }, 
        { key: 'action', value: 'Action' }
    ];
    const startIndex = (currentPage - 1) * 10;

    const rows = data?.data?.map((e, index) => ({
        id: e.id,
        index: startIndex + index + 1,   
        state_name: e.state_name,
    }));

    const handleSearchChange = (query) => {
        setInputValue(query);
        setCurrentPage(1);
    };

    return (
        <>
            {(data === "There is no State yet!" || error) ? (
                <p className='flex justify-center text-3xl'>{error ? error.message : "No State"}</p>
            ) : (
                <>
                    <div>
                        <CommonTable
                            showAddButton={true}
                            showSearch={true}
                            ButtonLabel={"Add State"}
                            headers={headers}
                            rows={rows || []}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            onEditClick={(id) => navigate(`/layout/EditState/${id}`)}
                            apiname={apiname}
                            deleteapiname={deleteapiname}
                            refetch={refetch}
                            onAddClick={handleAddClick}
                            searchQuery={inputValue}
                            onSearchChange={handleSearchChange}
                        />
                    </div>
                </>
            )}
            
        </>
    );
};

export default List_State;

