import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate,useParams } from 'react-router-dom';
import { useCreate } from '../../hooks/Api'
import Snackbar from '@mui/material/Snackbar';
import { message } from 'antd';
import Loader from '../../component/UI Component/Loader';
import { Select } from 'antd';
import { useFetch } from '../../hooks/Api';
import axios from 'axios';
import { useMutation } from 'react-query';

const Edit_Phone = () => {
  const { id } = useParams();

  const apiname = process.env.REACT_APP_API_PHONES?.toString();

  const { register, handleSubmit, formState: { errors }, setValue, clearErrors, reset } = useForm();
  const localStorageData = localStorage.getItem('items')
  const tokenData = localStorageData ? JSON.parse(localStorageData) : null
  const navigate = useNavigate()

  

  const [editData,setEditData] = useState()
  useEffect(() => {
    fetchData()
  }, [])
  
  const fetchData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      }
    });
    setEditData(res.data);
  }
  useEffect(() => {
    if(editData){
      reset(editData?.results)
    }
  }, [editData])






  const [state, setState] = useState({
    open: false
  });

 

  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  const getSnackbarStyle = () => {
    if (updateError?.response?.data?.message) {
      return {
        backgroundColor: 'red',
        color: 'white',
      };
    }
    if (updateData?.message) {
      return {
        backgroundColor: 'green',
        color: 'white',
      };
    }
    return {};
  };




  const { error:updateError, mutate:updateMutate, isError:updateIsError, isLoading:updateIsLoading, data:updateData, isSuccess:updateIsSuccess } = useMutation(async (data) => {
    const updateStoreUserDetails = await axios.put(`${process.env.REACT_APP_BASE_URL}${apiname}/${id}`, data, {
      headers: {
        'authorization': `Bearer ${tokenData?.token}`
      },
    })
    return updateStoreUserDetails.data
  })

  const onSubmit = (data) => {
    let finalData = {
      ...data,
    }
    updateMutate(finalData)
  }
 useEffect(() => {
    if (updateIsSuccess || updateData) {
      setState({ open: true });
      setTimeout(() => {
        navigate("/layout/phones");
      }, 1000);
    } else if (updateIsError) {
      setState({ open: true });
    }
  }, [updateData, updateError, updateIsSuccess,navigate]);

  return (
    <>
      <div className="">
        <div className="bg-white shadow rounded-lg p-4">
          <div className=" mb-6">
            <div className="text-2xl font-bold text-gray-800">Edit Phone</div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700" htmlFor="StoreManagerName">
                  Phone Number <span className='text-blue-500'> Ex: (xxx)-xxx-xxxx </span>
                </label>
                <div className="flex items-center border border-gray-300 rounded-md p-1">
                  {/* <svg className="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg> */}
                  <input
                  type="text"
                  id="mobile"
                  name="phone"
                  className="flex-1 p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  placeholder="Enter your Phone Number"
                  {...register('phone', {
                    required: 'Mobile number is required',
                    pattern: {
                      // value: /^(?:\+?(?:[0-9\-\(\)\/.\s]){6,13}[0-9]|[0-9]{10})$/,
                      value:/^\(\d{3}\)-\d{3}-\d{4}$/,
                      message: 'Please enter a valid phone number',
                    },
                  })}
                />
                </div>
                <p style={{ color: "Red" }}>{errors?.phone?.message}</p>
              </div>


          
            </div>
            <div className='text-center'>
              <button className="w-fit p-5 bg-pink-600 text-white py-2 rounded-md hover:bg-pink-700" onClick={handleSubmit(onSubmit)} disabled={updateIsLoading}>
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
    

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={state.open}
        onClose={handleClose}
        autoHideDuration={3000}
        ContentProps={{
          style: getSnackbarStyle(),
        }}
        onExited={() => {
          if (updateIsSuccess) {
            navigate("/layout/phones");
          }
        }}
        message={updateError?.response?.data?.message || updateData?.message || 'An error occurred'}
      />
      {
        updateIsLoading ? (
          <Loader/>
        ):null
      }

    </>
  );
}

export default Edit_Phone;
