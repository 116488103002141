import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useFetch } from '../../hooks/Api';
import Latan from '../../images/copy1.png';
import Latannewlogo from '../../images/sore_1.png';
const FormsByCategory = () => {
  const navigate = useNavigate()
  const form_category = localStorage.getItem('form_category')
  const store_uuid = localStorage.getItem('store_uuid')
  const localUserData = localStorage.getItem('user')
  const userData = localUserData ? JSON.parse(localUserData) : null;

    const { register, handleSubmit, formState: { errors }, reset ,setValue, clearErrors,getValues} = useForm();


    const [formValue,setFormValue] = useState()
    const apinameForm = process.env.REACT_APP_API_FORMS?.toString();
    const { data:formData, error:formError, isFetching:formIsFetching, refetch:formRefetch } = useFetch(apinameForm,1,100, store_uuid,form_category, userData?.id);
  
  
    const [formOptions,setFormOptions] = useState([])
    useEffect(()=>{
      if(formData){
        let data = formData?.data?.map((e)=>{return {label:e.form_name, value:e.id}})
        setFormOptions(data)
      }
    },[formData])
  
    const onChangeform = (value) => {
      setValue('form_id',value)
      // setFormValue(value)
      clearErrors('form_id')
  
    };
    
    const onSearchform = (value) => {
      console.log('search:', value);
    };

    const handleClick = () =>{
      const form_id = getValues('form_id')
      navigate(`/user/form/${form_id}`)
    }

    const isForm = getValues('form_id')

    console.log('isForm',isForm)
    
  return (
    <>
    
    
<div className="text-xl font-bold flex items-center justify-between w-full p-2 ">
        <img src={Latannewlogo} width={70} height={70}/>
        <img src={Latan} width={120} height={120}/>
        </div>

    <div className='h-78 my-48'>
        <div className='text-2xl font-bold flex justify-center items-center mb-12'>
            <h1> <span className='text-blue-600'>{form_category}</span> </h1>
        </div>
    <div className=' flex justify-center items-center mb-12'>
        <div className="lg:w-1/3 md:w-1/2 sm:w-full w-full  mx-8"> {/* Adjust width as needed */}
            <label className="block text-sm font-medium text-gray-700" htmlFor="">
                Select a Form
            </label>
            <Select
                {...register('form_id', { required: { value: true, message: 'Form Is Required' } })}
                className='w-full'
                style={{ height: '40px' }}
                showSearch
                value={formValue}
                placeholder="Select a Form"
                optionFilterProp="label"
                onChange={onChangeform}
                onSearch={onSearchform}
                options={formOptions}
            />
            <p style={{ color: "Red" }}>{errors?.store_id?.message}</p>
        </div>
    </div>

    <div className=' flex justify-center items-center '>

    <button
              onClick={handleClick}
              // onClick={()=>alert('click')}
              disabled={isForm == undefined}
            //   onClick={handlePassowrd(handlePasswordUpdate)}
              className={`${isForm == undefined ? "bg-pink-400 hover:bg-pink-400 cursor-not-allowed" : "bg-pink-600 hover:bg-pink-700"} text-white py-2 px-4 rounded `}
            >
              Click to fill
            </button>

    </div>
    </div>
    </>
  )
}

export default FormsByCategory